/**
 * @file The Helper Object.
 * @copyright 2004-2016 United Planet GmbH, Freiburg - All Rights Reserved.
 */

/* globals ix, oHtmlRoot: true, Helper */

/**
 * @class
 * @ignore
 * @description A static instance is available as <code>Helper</code>.
 */
function HelperMain() {
    /**
     * @ignore
     */
    this.oWin = self;
    /**
     * @ignore
     */
    this.oDoc = this.oWin.document;
    /**
     * encoding
     * @ignore
     */
    this.charset = "UTF-8";

    if (typeof oHtmlRoot === "undefined") {
        /* jshint -W020 */
        // TODO: refactoring please
        oHtmlRoot = self;
        /* jshint +W020 */
    }
    if (oHtmlRoot && oHtmlRoot.oUp && oHtmlRoot.oUp.oFormatInfo && typeof oHtmlRoot.oUp.oFormatInfo == "string") {
        this.charset = oHtmlRoot.oUp.oFormatInfo.charset;
    }
}

/**
 * @namespace Helper
 * @description Helper provides several auxiliary functions for Intrexx.
 */

/**
 * @ignore
 * @param {String} ch Hex character.
 * @return {Number|String} parsedValue Returns "no Hex value", if value wasn't a hex value.
 * @description Transform hex character to Number.
 */
HelperMain.prototype.hexDigitToInt = function (ch) {
    var parsedValue = "";
    if ("0" <= ch && ch <= "9") {
        parsedValue = ch - "0";
    }
    if ("A" <= ch && ch <= "F") {
        ch = "0x" + ch;
        parsedValue = 10 + parseInt(ch, 16) - parseInt("0xA", 16);
    }
    if ("a" <= ch && ch <= "f") {
        ch = "0x" + ch;
        parsedValue = 10 + ch - parseInt("0xa", 16);
    }

    if (parsedValue) {
        return parsedValue;
    } else {
        return "no Hex Value";
    }
};

/**
 * @memberof Helper
 * @function htmlEncode
 * @param {String} html
 * @param {Boolean} [insertNbsp=false] Shall " " with "&nbsp;" be replaced.
 * @param {Boolean} [insertBr=false] Shall "\n" with "&lt;br&gt;" be replaced.
 * @return {String}
 * @description Encodes particular html characters.
 */
HelperMain.prototype.htmlEncode = function (html, insertNbsp, insertBr) {
    var out = [];
    for (var i = 0; i < html.length; i++) {
        out.push(this._htmlEncode(html.charAt(i), insertNbsp, insertBr));
    }
    return out.join("");
};

/**
 * @ignore
 */
HelperMain.prototype.xmlEncode = function (string) {
    return string
        .replace(/&/g, "&" + "amp;")
        .replace(/</g, "&" + "lt;")
        .replace(/>/g, "&" + "gt;")
        .replace(/'/g, "&" + "apos;")
        .replace(/"/g, "&" + "quot;");
};

/**
 * @ignore
 */
HelperMain.prototype._htmlEncode = function (character, insertNbsp, insertBr) {
    var encoded = character;
    switch (character) {
        case "&":
            encoded = "&amp;";
            break;
        case '"':
            encoded = "&quot;";
            break;
        case ">":
            encoded = "&gt;";
            break;
        case "<":
            encoded = "&lt;";
            break;
        case " ":
            if (insertNbsp) {
                encoded = "&nbsp;";
            }
            break;
        case "\n":
            if (insertBr) {
                encoded = "<br>";
            }
            break;
    }
    return encoded;
};

/**
 * @ignore
 */
HelperMain.prototype.htmlDecode = function (text, insertNbsp) {
    return ix.util.htmlDecode(text, insertNbsp);
};

/**
 * @ignore
 * @param {Object[]} list
 * @return {String}
 * @description Transforms an Array to a string.<br>
 * "||" is the separator where "|" as escaped with "|0".
 */
HelperMain.prototype.doCharStuffing = function (list) {
    return ix.util.doCharStuffing(list);
};

/**
 * @ignore
 * @param {String} input
 * @return {String[]}
 * @description Splits a string to an Array<br>
 * "||" is used as separator, while "|0" is unescaped to "|".
 */
HelperMain.prototype.undoCharStuffing = function (input) {
    return ix.util.undoCharStuffing(input);
};

/**
 * @ignore
 * @deprecated use FUP.getReloadArguments
 * @param {HTMLElement} element
 * @param {String} url
 * @return {String} url
 */
HelperMain.prototype.setUrlSourceParams = function (element, url) {
    var mapSourceParams = ix.ajax().getSourceParams(element);

    for (var name in mapSourceParams) {
        if (mapSourceParams[name] !== undefined) {
            url = Helper.setUrlValueByParam(name, mapSourceParams[name], url);
        }
    }
    return url;
};
