/**
 * @file Common mobile functions.
 * @copyright 2004-2016 United Planet GmbH, Freiburg - All Rights Reserved.
 */

/* global
   IDCOUNTERSEP: true, debug, Browser, Notifier, Helper, ix,
   ErrorHandler, positionActionPanel: true, upActionControl
*/

/* exported
   upObjectSetProperties, warnDeprecated, getLoc, logout,
   toggleVisibility, COOKIEVAR, QSVAR, upResponseHandler,
   isPortalLink, slideContainer, uplActionPanelHideOnLoad, extendUpObjectFromUrl
*/

/**
 * @ignore
 */
if (typeof IDCOUNTERSEP === "undefined") {
    IDCOUNTERSEP = "__";
}

/**
 * @ignore
 * @param {Object} properties
 */
function upObjectSetProperties(properties) {
    for (var property in properties) {
        if (properties.hasOwnProperty(property)) {
            this[property] = properties[property];
        }
    }
}

/**
 * @ignore
 * @param {String} name
 * @description Display a dreprecated warning on the console.
 */
function warnDeprecated(name) {
    debug("The Method " + (name ? name + " " : "") + "is deprecated");
}

//////////////////////////////////////////////////////////////////////////////////////
//                                ERROR-HANDLING                                    //
//////////////////////////////////////////////////////////////////////////////////////
/**
 * @class Error
 * @private
 * @description Errorhandling
 */
function Error() {}

/**
 * @memberof Error
 * @private
 * @param {Exception} e
 * @param {Boolean} silent
 * @description shows the Message of an Exception in an alert Box
 */
Error.handleJsException = function (e, silent) {
    var txt = "";
    if (e.message) {
        txt += e.message + "\n";
    }
    if (e.toString) {
        txt += e.toString();
    }
    if (silent) {
        console.log(txt);
    } else {
        Notifier.status.error(txt, "JavaScript Exception");
    }
};

/**
 * @class
 * @see QSVAR
 * @private
 * @description Holds cookie variables, which are not hexEncoded.
 */
function UpCOOKIEVAR() {
    /**
     * @private
     * @description An array of the names of all cookie variables, which are not hexencoded.
     */
    this.CO_NOT_ENCODED = [];
    this.CO_NOT_ENCODED.push(/^co_Lang$/);
    this.CO_NOT_ENCODED.push(/^co_Locale$/);
    this.CO_NOT_ENCODED.push(/^co_Layout(_[0-9A-F]{40})?$/);
    // this.isEncoded = COOKIEVARIsEncoded;
}

/**
 * @private
 * @param {String} name Name of a cookie variable as string
 * @return {Boolean}
 * @description Is the cookie variable name hexEncodeStringd?
 */
UpCOOKIEVAR.prototype.isEncoded = function (name) {
    return !this.CO_NOT_ENCODED.some(function (i) {
        return i.test(name);
    });
};

/**
 * @private
 */
var COOKIEVAR = new UpCOOKIEVAR();

/**
 * @see COOKIEVAR
 * @class
 * @private
 * @description Holds querystring variables, which are not hexEncodeStringd.
 */
function UpQSVAR() {
    /**
     * @private
     * @description
        An array of the names of querystring variables, which are not hexencoded.
    */
    this.RQ_NOT_ENCODED = [];
    this.RQ_NOT_ENCODED.push("rq_Lang");
    this.RQ_NOT_ENCODED.push("rq_command");
    this.RQ_NOT_ENCODED.push("rq_MenuGuid");
    this.RQ_NOT_ENCODED.push("rq_Layout");
    this.RQ_NOT_ENCODED.push("rq_TargetPageGuid");
    this.RQ_NOT_ENCODED.push("rq_AppGuid");
    this.RQ_NOT_ENCODED.push("rq_SourceAppGuid");
    this.RQ_NOT_ENCODED.push("rq_SourcePageGuid");
    this.RQ_NOT_ENCODED.push("rq_DatarangeGuid");
    this.RQ_NOT_ENCODED.push("rq_NoSessionTouch");
    this.RQ_NOT_ENCODED.push("rq_ReloadParent");
    this.RQ_NOT_ENCODED.push("rq_SortBy");
    this.RQ_NOT_ENCODED.push("rq_PortalGuid");
    this.RQ_NOT_ENCODED.push("urn:schemas-unitedplanet-de:ixservlet:name");
    // this.isEncoded = QSVARIsEncoded;
}

/**
 * @ignore
 * @description Is the querystring variable name hexencoded?
 * @param {String} name Name of querystring variable as string.
 * @return {Boolean}
 */
UpQSVAR.prototype.isEncoded = function (name) {
    for (var i = 0; i < this.RQ_NOT_ENCODED.length; i++) {
        if (this.RQ_NOT_ENCODED[i] === name) {
            return false;
        }
    }
    return true;
};

var QSVAR = new UpQSVAR();

/**
 * @ignore
 * @name handleLocationError
 * @param {Exception} ex
 * @description shows a location error in an alert box
 */
function handleLocationError(ex) {
    alert(ex.message);
}

/**
 * @function
 * @name getLoc
 * @private
 * @param {Object} position
 * @return {String}
 * @description Get the coordinates from an position object.
 */
function getLoc(position) {
    var latitude = position.coords.latitude;
    var longitude = position.coords.longitude;
    return latitude + "," + longitude;
}

/**
 * @memberof Browser
 * @private
 * @param {String} id
 * @description Changes the current position in the href of an element.
 */
Browser.changeMapsUrl = function (id) {
    if (typeof navigator.geolocation === "undefined") {
        alert("No Geolocation Support!");
    }
    navigator.geolocation.getCurrentPosition(function (position) {
        var strLoc = position.coords.latitude + "," + position.coords.longitude;
        location.href = document.getElementById(id).href.replace(/geolocation/, strLoc);
    }, handleLocationError);
};

/**
 * @ignore
 */
var upResponseHandler = {};

/**
 * @ignore
 * @return {Promise}
 * @description Logout method for mobile devices.
 */
function logout() {
    var url = ix.util.getBaseUrl("internal/system/vm/html/login/getLogout.vm");
    return $.ajax({
        url: url,
        method: "POST",
        success: function () {
            top.location.replace(top.location.pathname);
        },
        error: function () {
            ErrorHandler.notify("Unexpected exception while processing logout.");
        },
    });
}

/**
 * @ignore
 * @param {String} containerName
 * @param {Number} [cookie]
 * @param {HTMLElement|jQuery} [source]
 * @description Toggles visibility used by Actioncontrols with @linktype=15.
 */
var toggleVisibility = function (containerName, cookie, source) {
    var oContainer = document.getElementById("ID_" + containerName);
    var $container = $(oContainer);
    var _source = null;
    var image = null;

    if (typeof source === "string" || source instanceof String) {
        try {
            _source = $("#ID_" + source);
        } catch (ex) {
            console.error("no element found");
        }
    }
    if (typeof source == "object" && (source instanceof HTMLElement || source instanceof jQuery)) {
        _source = $(source);
    }
    if (_source.length) {
        image = _source.find("img");
    }

    $container.toggle();
    if (_source.length) {
        _source.attr("aria-expanded", $container.is(":visible"));
        image.swapAttr("src", "data-imageonsrc");
    }
    return;
};

/**
 * @name uplActionPanelHideOnLoad
 * @function
 * @private
 * @description Hides action panel elements on page load on mobile non-webkit Browsers.
 */
function uplActionPanelHideOnLoad() {
    $('[onclick^="slideContainer"]').each(function () {
        var targetId = this.getAttribute("onclick").split("slideContainer('")[1].split("');")[0];
        if (!targetId) {
            return;
        }
        var target = document.getElementById(targetId);
        $(target).hide();
    });
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////                           SLIDE_CONTAINER / ACTION_PANEL                        /////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**
 * @function
 * @private
 * @description action panel
 * @param {String} containerId the id of the action panel container
 */
var slideContainer = function (containerId) {
    if (!containerId) {
        return;
    }
    var container = $("#" + containerId);
    container.attr("role", "action-panel");

    container.toggle();
    container.attr("aria-expanded", container.is(":visible"));

    positionActionPanel(containerId);
    try {
        window.clearInterval(window[containerId + "_interval"]);
    } catch (ex) {
        console.error("Couldn't Clear Interval, Property Not Found Exception");
        console.error(ex);
    }
};

/**
 * @ignore
 * @param {String} containerId
 * @description (Re-)position the action Panel at the screen Bottom
 */
positionActionPanel = function (containerId) {
    if (!containerId) {
        return;
    }
    var container = $("#" + containerId);
    var st = $(window).scrollTop();
    var rwh = $(window).height();
    var mh = container.outerHeight(true);
    var wh = window.innerHeight; // on iOS we need innerHeight
    wh = wh || rwh;
    var t = st + wh - mh;
    container.css({
        zIndex: "99",
        top: t < 0 ? 0 : t - $("#Container_Stage").offset().top,
        position: "absolute",
    });
};

/**
 * @ignore
 * @param {String} url
 * @return {Boolean}
 * @description Checks if a given link matches to baseurl -> assume that this is a portal link.
 */
function isPortalLink(url) {
    // relative url
    var ret = false;
    // eslint-disable-next-line no-useless-escape
    if (url.match(/^(?:\?|\&)/)) {
        ret = true;
    } else {
        var baseUrl = "" + Helper.getBaseUrl().match(/\/.*\//);
        var strUrl = typeof url !== "undefined" && !!url && !!url.length ? url : "";
        var re = baseUrl.replace(/\//g, "\\/");
        //!!url.match(/^(?:\?|\&)/)
        // eslint-disable-next-line no-useless-escape
        re = re.replace(/\&/g, "\\&");
        re = re.replace(/\?/g, "\\?");
        re = re.replace(/\+/g, "\\+");
        // eslint-disable-next-line no-useless-escape
        re = re.replace(/\-/g, "\\-");
        re = new RegExp(re + ".*");
        ret = !!strUrl.match(re);
    }
    return ret;
}

/**
 * @ignore
 * @param {String} url
 * @param {upActionControl} [actionControl]
 * @return {upActionControl}
 * @description Create or extend an upActionControl from a given intrexx url and returns it.
 */
function extendUpObjectFromUrl(url, actionControl) {
    var oUp = actionControl || new upActionControl();
    var _oIgnore = {
        rq_VM: "rq_VM",
    };
    if (url) {
        // eslint-disable-next-line no-useless-escape
        var _arr = url.match(/((?!\?|\&)[a-zA-Z0-9_]*=)([0-9A-Fa-f]*)/g);
        if (_arr) {
            for (var i = 0; i < _arr.length; i++) {
                // eslint-disable-next-line no-useless-escape
                var _k = _arr[i].match(/((?!\?|\&)[a-zA-Z0-9_]*=)([0-9A-Fa-f]*)/)[1]; // key
                // eslint-disable-next-line no-useless-escape
                _k = _k.replace(/\=$/, "");
                // eslint-disable-next-line no-useless-escape
                var _v = _arr[i].match(/((?!\?|\&)[a-zA-Z0-9_]*=)([0-9A-Fa-f]*)/)[2]; // value
                if (_k === "rq_RecId") {
                    _v = Helper.hexDecodeString(_v);
                    oUp.linkType = "0";
                } else {
                    oUp.linkType = "2";
                }
                if (_k in oUp.oTarget) {
                    oUp.oTarget[_k] = _v;
                } else if (_k in oUp.oSource) {
                    oUp.oSource[_k] = _v;
                } else if ((!!_k.match(/^rq_/) || !!_k.match(/^qs_/)) && !(_k in _oIgnore)) {
                    var prfx = !oUp.oTarget.addParam.length ? "?" : "&";
                    oUp.oTarget.addParam = oUp.oTarget.addParam + prfx + _k + "=" + _v;
                }
            }
        }
        oUp.oAjaxSettings.strUrl = url;
    }
    return oUp;
}
// eslint-disable-next-line no-unused-vars
(function ($, window) {
    var pluginName = "confirmDelete";

    function ConfirmDelete(element) {
        $(element).on("click", function (e) {
            if (confirm(ix.text.i18n.get("CTRL_CONFIRM_DELETE"))) {
                return true;
            } else {
                e.stopPropagation();
                e.stopImmediatePropagation();
                e.preventDefault();
                return false;
            }
        });
    }

    $.fn[pluginName] = function (options) {
        return this.each(function () {
            if (!$.data(this, "plugin_" + pluginName)) {
                $.data(this, "plugin_" + pluginName, new ConfirmDelete(this, options));
            }
        });
    };
})(jQuery, window);
