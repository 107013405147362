/**
 * @file upControl
 * @copyright 2004-2016 United Planet GmbH, Freiburg - All Rights Reserved.
 */
/* globals upObject */
/**
    @class upControl
    @private
    @param {Object} options
    @description
    General upControl Class.
*/
function upControl(options) {
    this.upType = "upControl";
    this.userName = "";
    this.defaults = {
        oHtml: null,
    };
    this.options = $.extend({}, this.defaults, options);
    this.oHtml = this.options.oHtml;
    return this;
}
//////////////////////////////////////////////////
//                   INITIALIZATION             //
//////////////////////////////////////////////////
/**
    @private
    @constructs
    @description
    upControl is inherited from upObject
*/
upControl.prototype = new upObject();

//////////////////////////////////////////////////
//                   SETTER                     //
//////////////////////////////////////////////////
/**
   @methodOf upControl
   @private
   @description Set a control to be required or not required.
   @param {boolean} state true = required, false = not required, undefined = required
*/
upControl.prototype.setRequired = function (state, p_oHtml) {
    var l_oHtml = p_oHtml ? p_oHtml : this.oHtml;
    if (l_oHtml === null) {
        return false;
    }
    if (typeof state != "boolean") {
        state = true;
    }
    if (typeof this.required !== "undefined") {
        this.required = !!state === true ? "1" : "0";
    }
    l_oHtml.required = state;
    if (l_oHtml !== null) {
        $(l_oHtml).attr("aria-required", state);
        if (!state) {
            $(l_oHtml).removeAttr("aria-required");
        }
        $(l_oHtml).attr("aria-invalid", false);
    }
    return true;
};
/**
   @methodOf upControl
   @private
   @param {String} p_Input
   One-digit input.
   @returns {String} input
   Modified two-digit value
   @description
   Fills a one-digit Input with a zero on the left side and returns a two-digit value.
*/
upControl.prototype.fillLeading = function (p_Input) {
    var input = "" + p_Input;
    if (input.length > 1) {
        return input;
    } else {
        return "0" + input;
    }
};

/**
 * @private
 */
upControl.prototype.getTable = function () {
    var ret = null;
    var oHtml = this.getTableNode();
    if (oHtml !== null && typeof oHtml.oUp == "object") {
        ret = oHtml.oUp;
    }
    return ret;
};
