/**
 * @file upDataControl
 * @copyright 2004-2016 United Planet GmbH, Freiburg - All Rights Reserved.
 */
/* globals upDataControl, log, Notifier  */

/**
 * @class
 * @private
 */
//////////////////////////////////////////////////
//                   GETTER                     //
//////////////////////////////////////////////////
/**
    @methodOf upDataControl
    @public
    @description
    get state of the readonly attribute for the given input element
    @param {object} p_oHtml input element
    @return {Boolean} readOnly state
 */
upDataControl.prototype.getReadOnly = function () {
    if (!this || this === null) {
        Notifier.status.info(self.oUp.oMessage.DEPENDENCY_ERROR_TARGET_INVALID);
        return false;
    } else {
        return this.readOnly === "1" || this.readOnly === true ? true : false;
    }
};
//////////////////////////////////////////////////
//                   SETTER                     //
//////////////////////////////////////////////////

/**
    @methodOf upDataControl
    @public
    @param {Boolean} isReadOnly Flag that is indicating whether the control has to be set to readonly (true) or if the controls readonly state should be removed (false). Defaults to true.
*/
upDataControl.prototype.setReadOnly = function (isReadOnly) {
    var bState = isReadOnly !== undefined ? (isReadOnly === 1 || isReadOnly === true ? true : false) : true;
    var ret = false;
    if (!this || this === null) {
        if (window.console) {
            console.log(self.oUp.oMessage.DEPENDENCY_ERROR_TARGET_INVALID);
            console.log(this);
        } else {
            log(self.oUp.oMessage.DEPENDENCY_ERROR_TARGET_INVALID);
        }
        Notifier.status.info(self.oUp.oMessage.DEPENDENCY_ERROR_TARGET_INVALID);
        ret = false;
    } else {
        this.readOnly = bState;
        if (this.oHtml) {
            if (bState === true) {
                this.oHtml.setAttribute("readonly", bState);
            } else {
                this.oHtml.removeAttribute("readOnly");
            }
            ret = true;
        } else {
            if (window.console) {
                console.log(self.oUp.oMessage.DEPENDENCY_ERROR_TARGET_INVALID);
            } else {
                log(self.oUp.oMessage.DEPENDENCY_ERROR_TARGET_INVALID);
            }
        }
    }
    return ret;
};
