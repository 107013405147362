/**
 * @file API functions
 * @copyright 2004-2016 United Planet GmbH, Freiburg - All Rights Reserved.
 */

/* globals Helper, Loader, ajaxLoader, extendUpObjectFromUrl, ix */

(function (root, factory) {
    /**
     * @namespace API
     * @description General API methods.
     */
    root.API = factory();

    // publish everything from API to global for backward compatibility
    for (var prop in root.API) {
        if (root.API.hasOwnProperty(prop)) {
            root[prop] = root.API[prop];
        }
    }
})(this, function () {
    "use strict";

    var ns = {};

    /**
     * @function getDateObject
     * @memberof API
     * @param {Object} oHtml Reference to a html input control of types "date", "time" or "date & time".
     * @return {Date}
     * @description Creates an JavaScript date object from a html control.
     * @example
     * getDateObject(getElement("A84E5E7F9EA9ED1A829DB98DBF5459F59961FA3B"));
     */
    ns.getDateObject = function (oHtml) {
        return Helper.getDateObject(oHtml);
    };

    /**
     * @ignore
     * @deprecated
     * @function toLocalString
     * @memberof API
     * @param {Object} element Reference to a html input control of types "integer", "double", "currency" (format settings can be appended here, too).
     * @param {String} value Number string or date string.
     * @return {String} Local formatted number as string or local formatted datetime as string
     * @description Creates a local formatted string from an number string or a datetime string.
     * @example
     * toLocalString(getElement("DDB279D4974A17DB27A89F58DB36F7BF4E10DA13"),"123.45");
     */
    ns.toLocalString = function (element, value) {
        return Helper.toLocalString(element, value);
    };

    /**
     * @ignore
     * @deprecated
     * @function writeLocalString
     * @memberof API
     * @param {Object} element Reference to a html input control of types "date", "time", "date & time", "integer", "double", "currency" (format settings can be appended here, too).
     * @param {String} value Value to localize.
     * @return {Boolean}
     * @description Creates a local formatted string from an number string or a datetime string and writes it as value into a html control.
     * @example
     * writeLocalString(getElement("DDB279D4974A17DB27A89F58DB36F7BF4E10DA13"),"123.45");
     */
    ns.writeLocalString = function (element, value) {
        return Helper.writeLocalString(element, value);
    };

    /**
     * @ignore
     * @deprecated
     * @memberof API
     * @function dateComparedToNow
     * @param {Date} date Reference to an html input control of type "date", "time" or "datetime"
     * @return {Number}
     * @description Compares a value of a date control with the current date. Imperative for input and view controls (for view controls the expert attribute <code>jsobject = true (Boolean)</code> has to be set.)
     * @example
     * dateComparedToNow(getElement("5F7S5E7F9EA9ED1A829DB98DBF5459F59961FA3B"));
     */
    ns.dateComparedToNow = function (date) {
        return Helper.dateComparedToNow(date);
    };

    /**
     * @ignore
     * @deprecated
     * @memberof API
     * @function dateAdd
     * @param {String} interval Possible values: "ms" - for milliseconds; "s" - for seconds; "mi" - for minutes; "h" - for hours; "d" - for days; "mo" - for months; "y" - for years;
     * @param {Number} number value for given interval, that should be added to date. Negative values are also possible.
     * @param {Date} date
     * @return {Date}
     * @description Adds or subtracts a interval to/from a given date.
     * @example
     * var dtNow = new Date();
     * var dtAdd1 = dateAdd("d", 1,  dtNow); // adds 1 day to current date.
     * var dtAdd2 = dateAdd("h", -2, dtNow); // subtracts 2 hours from current date.
     */
    ns.dateAdd = function (interval, number, date) {
        return Helper.dateAdd(interval, number, date);
    };

    /**
     * @ignore
     * @deprecated
     * @memberof API
     * @function getLocaleShortDateString
     * @param {Date} date
     * @param {String} [format="dd.MM.yyyy"]
     * @return {String} output
     * @description Converts a date to a short date string representation.
     */
    ns.getLocaleShortDateString = function (date, format) {
        var l = format || "dd.MM.yyyy";
        var y = date.getFullYear();
        var m = date.getMonth() + 1;
        var d = date.getDate();
        var f = l;

        function z(s) {
            s = "" + s;
            return s.length > 1 ? s : "0" + s;
        }
        f = f.replace(/yyyy/, y);
        f = f.replace(/yy/, String(y).substr(2));
        f = f.replace(/MM/, z(m));
        f = f.replace(/M/, m);
        f = f.replace(/dd/, z(d));
        f = f.replace(/d/, d);
        return f;
    };

    /**
     * @ignore
     * @deprecated
     * @memberof API
     * @function dateFromString
     * @param {String} value
     * @return {Date}
     * @description Generates a date object from an ISO-date-string.
     */
    ns.dateFromString = function (value) {
        var arr = value.split(/[^0-9]/);
        var a = [];
        for (var i = 0; i < arr.length; i++) {
            a.push(parseInt(arr[i], 10));
        }
        return new Date(a[0], a[1] - 1 || 0, a[2] || 1, a[3] || 0, a[4] || 0, a[5] || 0, a[6] || 0);
    };
    /**
     * @function ajaxVTL
     * @memberof API
     * @param {String} vtlFile Path to the .vm-file - .vmi are not reachable.
     * @param {Object} [settingsAjax] AJAX settings e.g. {dataType: 'json'}, http://api.jquery.com/jQuery.ajax/, additional intrexx properties: urlParameters
     * @param {Object} [appContext] The upFuncPart or any HTMLElement of the application page.
     * @return {Promise}
     * @description Sends a request to the provided .vm-file.
     * @example
     * ajaxVTL("internal/system/vm/custom/test.vm", {
     *     dataType: "json",
     *     urlParameters: {
     *         "rq_Name1": "value1",
     *         "rq_Name2": "value2"
     *     },
     *     success: function(dataJSON){
     *         //e.g. dataJSON response is:
     *         //{"myobject":{"property1":"propertyValue1"}}
     *         alert(dataJSON.myobject.property1);
     *     }
     * });
     */
    ns.ajaxVTL = function (vtlFile, settingsAjax, appContext) {
        try {
            var ajax = ix.ajax();
            if (appContext !== undefined && appContext !== null) {
                ajax.setAppContext(appContext);
            }
            settingsAjax = jQuery.extend(
                {
                    dataType: "xml",
                    urlParameters: null,
                    ixWaitAnim: {
                        element: null,
                        type: null,
                    },
                },
                settingsAjax
            );
            if (["json", "html", "text", "script"].indexOf(settingsAjax.dataType) === -1) {
                settingsAjax.dataType = "xml";
            }
            return ajax.requestVm(vtlFile, settingsAjax);
        } catch (e) {
            console.error("ajaxVTL rejected. " + e.message);
            return $.Deferred().reject(e).promise();
        }
    };

    /**
     * @function ajaxAppVTL
     * @memberof API
     * @param {String} vtlFile Path to the application .vm-file - .vmi are not reachable.
     * @param {Object} [settingsAjax] AJAX settings e.g. {dataType: 'json'}, http://api.jquery.com/jQuery.ajax/, additional intrexx properties: urlParameters
     * @param {Object} [appContext] The upFuncPart or any HTMLElement of the application page.
     * @return {Promise}
     * @description Sends a request to the provided application resource .vm-file underneath internal/application/resource/APPGUID/.
     * @example
     * ajaxAppVTL("test.vm", {
     *    dataType: "json",
     *    success: function(dataJSON){
     *       //e.g. dataJSON response is:
     *       //{"myobject":{"property1":"propertyValue1"}}
     *       alert(dataJSON.myobject.property1);
     *   }
     * }, getElement(GUID));
     *
     * ajaxAppVTL("test.vm", {
     *    dataType: 'text',
     *    data: {
     *           fr_param1: Browser.getValue(getElement("GUID_FROM_FIELD"))
     *    },
     *    success: function(data){
     *       Browser.setValue(getElement("GUID_STATIC_TEXTFIELD"), data);
     *    }
     * }, getElement("GUID_FROM_ANY_FIELD"));
     */
    ns.ajaxAppVTL = function (vtlFile, settingsAjax, appContext) {
        try {
            var ajax = ix.ajax();
            ajax.setAppContext(appContext);
            vtlFile = "internal/application/resource/" + ajax.appContext.appGuid + "/" + vtlFile;
            settingsAjax = jQuery.extend(
                {
                    dataType: "xml",
                    urlParameters: null,
                    ixWaitAnim: {
                        element: null,
                        type: null,
                    },
                },
                settingsAjax
            );
            if (["json", "html", "text", "script"].indexOf(settingsAjax.dataType) === -1) {
                settingsAjax.dataType = "xml";
            }
            return ajax.requestVm(vtlFile, settingsAjax);
        } catch (e) {
            console.error("ajaxAppVTL rejected. " + e.message);
            return $.Deferred().reject(e).promise();
        }
    };

    /**
     * @ignore
     * @deprecated
     * @memberof API
     * @function getFuncPart
     * @deprecated use Helper.getFuncPart instead
     * @param {Object} element
     * @description Get Informations on the current page and AppGuid from the functional part
     * @return {Object} Object holding information on the current AppGuid and PageGuid.
     */
    ns.getFuncPart = function (element) {
        var funcPart = null;
        var form = null;
        if (arguments.length > 0) {
            if (element) {
                if ($(element).parents().length && !!$(element).parents().find('[data-role="upTooltip"]').length) {
                    funcPart = $(element)
                        .parents()
                        .find('[data-role="upTooltip"]')
                        .find('[data-container="upFuncPart"]');
                }
            }
        }
        if (!funcPart) {
            funcPart = $('[data-container="upFuncPart"]');
        }
        form = funcPart.find("form")[0];
        var appGuid = funcPart.attr("data-appguid");
        var pageGuid = funcPart.attr("data-pageguid");
        var ret = null;
        if (typeof appGuid === "undefined" || typeof pageGuid === "undefined") {
            form = funcPart.find("form");
            var action = form.attr("action") || null;

            if (typeof form !== "undefined" && action !== null) {
                appGuid = action.match("rq_AppGuid=([0-9a-fA-F]*)")[1];
                pageGuid = action.match("rq_TargetPageGuid=([0-9a-fA-F]*)")[1];
            }
        }
        ret = {
            funcPart: funcPart[0],
            form: form,
            appGuid: appGuid,
            pageGuid: pageGuid,
        };
        return ret;
    };

    /**
     * @ignore
     * @deprecated
     * @memberof API
     * @function loadUrl
     * @param {String} url
     * @description Shorthand for pageloader / ajaxloader.
     */
    ns.loadUrl = function (url) {
        ajaxLoader.get(extendUpObjectFromUrl(url));
    };

    /**
     * @ignore
     * @deprecated
     * @memberof API
     * @function loadCSSFileOnDemand
     * @param {String} file
     * @deprecated Use Loader.loadCSSFileOnDemand instead.
     * @description Loads a css file via javascript.
     */
    ns.loadCSSFileOnDemand = function (file) {
        Loader.loadCssFileOnDemand(file);
    };

    /**
     * @function triggerUserWorkflowEvent
     * @memberof API
     * @param {String} eventGuid The parameter "eventGuid" of the generic event handler.
     * @param {Object} [urlParameters] An object containing name value pairs, which will be part of the Query-String.
     * @param {Object} [ajaxParameters] An object containing synchronous mode.
     * @return {Promise}
     * @description Triggers an User Workflow Event, which is always a POST-Request.
     * @example
     * // Example 1
     * // Trigger event with guid <EVENTGUID>. Define a generic event handler with the class "de.uplanet.lucy.server.workflow.eventhandler.UserWorkflowEventHandler" before.
     * triggerUserWorkflowEvent("<EVENTGUID>", {"param1":"value1","param2":"value2"}, {"async": true});
     *
     * // Example 2
     * $.when(triggerUserWorkflowEvent("<EVENTGUID>", {"param1":"value1", "param2":"value2"}, {"async": true}))
     *     .done(function() {
     *        alert("done");
     *     })
     *     .fail(function() {
     *        alert("failed :(");
     *     });
     */
    ns.triggerUserWorkflowEvent = function (eventGuid, urlParameters, ajaxParameters) {
        var r = $.Deferred();
        if (typeof eventGuid === "string") {
            // prepare url parameters
            urlParameters = urlParameters || {};
            $.extend(urlParameters, {
                qs_wfEventGuid: eventGuid,
                rq_command: "workflow",
            });
            // build the url
            var url = ix.util.getBaseUrl();
            for (var param in urlParameters) {
                if (urlParameters.hasOwnProperty(param)) {
                    url = Helper.setUrlValueByParam(param, urlParameters[param], url);
                }
            }
            // some parameters are fixed
            $.ajax(
                $.extend(true, {}, ajaxParameters, {
                    url: url,
                    method: "POST", // we only use POST due to CSRFToken
                    type: undefined, // no deprecated type, method is used
                    dataType: "xml", // connector gives always xml
                    success: function (data, status, jqXHR) {
                        var $error = $(data).find("error");
                        if ($error.length > 0) {
                            // connector gave us an error
                            r.reject($error.text());
                        } else {
                            r.resolve(data, status, jqXHR);
                        }
                    },
                })
            );
        } else {
            r.reject("Parameter 'eventGuid' seems to be not of type String.");
        }
        return r.promise();
    };

    return ns;
});
