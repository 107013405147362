/**
 * @file Deprecated mobile ajax classes.
 * @copyright 2004-2016 United Planet GmbH, Freiburg - All Rights Reserved.
 */
/* globals AjaxLoader:true,  SILENT_MODE:true, ajaxLoader, ix: true */
/* exported PageLoader */
/**
    @global
    @field
    @name SILENT_MODE
    @private
    @description
    silent mode flag for processXML error handling. If set to true, all errors go silently to console.
*/
if (typeof SILENT_MODE === "undefined") {
    SILENT_MODE = false;
}
//////////////////////////////////////////////////////////////////////////////////////////////////////
//                                      PAGELOADER                                                  //
//////////////////////////////////////////////////////////////////////////////////////////////////////
/**
    @class UpPageLoader
    @private
    @deprecated
    @description
    PageLoader loads a page over Http
*/
function UpPageLoader() {}
var PageLoader = new UpPageLoader();
/**
    @private
    @deprecated
    @methodOf UpPageLoader
    @param {Object} oParam
    @description
    loads a page by PageGuid, AppGuid, RecId
*/
UpPageLoader.prototype.load = function (oParam) {
    AjaxLoader.load(oParam);
};
/**
    @private
    @methodOf UpPageLoader
    @deprecated
    @param {String} url
    @description
    loads a page by a given url
*/
UpPageLoader.prototype.loadUrl = function (url) {
    AjaxLoader.loadUrl(url);
};
/**
    @private
    @methodOf UpPageLoader
    @deprecated
    @param {Object} oParam
    @description
    loads a page by PageGuid, AppGuid, RecId
*/
UpPageLoader.prototype.post = function (oParam) {
    AjaxLoader.post(oParam);
};
///////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////                                   AJAX_LOADER                                   /////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**
    @class AjaxLoader
    @private
    @deprecated
    @description
    ajaxLoader main class
*/
function UpAjaxLoader() {
    this.firstLoad = true;
}
/**
    @private
    @constructs AjaxLoader
*/
AjaxLoader = new UpAjaxLoader();
/**
    @methodOf UpAjaxLoader
    @private
    @deprecated
    @param {Object} oParam
    @description
    loads a page via http get
*/
UpAjaxLoader.prototype.load = function (oParam) {
    var settings = {
        method: "GET",
    };
    settings = $.extend(true, {}, settings, ix.loader.getSettingsFromActionControl(oParam));
    return ix.loader.process(settings);
};
/**
    @methodOf UpAjaxLoader
    @private
    @deprecated
    @param {String} url
    @param {String} navType
    @param {Object} oHtml
    @returns {Boolean} status
    @description
    loads an url via http get
*/
UpAjaxLoader.prototype.loadUrl = function (url, navType, oHtml) {
    return ajaxLoader.loadUrl(url, navType, oHtml);
};
/**
    @methodOf UpAjaxLoader
    @private
    @deprecated
    @param {Object} oParam
    @returns {Boolean} status
    @description
    Loads a page via http POST
*/
UpAjaxLoader.prototype.post = function (oParam) {
    var settings = {
        method: "POST",
    };
    settings = $.extend(true, {}, settings, ix.loader.getSettingsFromActionControl(oParam));
    return ix.loader.process(settings);
};
/**
    @methodOf UpAjaxLoader
    @private
    @see BrowserHistoryEntry
    @returns {Boolean} status <code>true</code>
    @description
    Loads last loaded functional part.
*/
UpAjaxLoader.prototype.loadLastPage = function (navType) {
    return ajaxLoader.loadLastPage(navType);
};
/**
    @methodOf UpAjaxLoader
    @private
    @param historyEntry BrowserHistoryEntry object
    @return {Boolean} status <code>true</code>
    @see BrowserHistoryEntry
    @description
    Loads a  functional part based on a browser history entry.
*/
UpAjaxLoader.prototype.loadFromHistory = function (historyEntry, navType) {
    return ajaxLoader.loadFromHistory(historyEntry, navType);
};
