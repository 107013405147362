/**
 * @file Gesture handling for mobile devices
 * @copyright 2004-2016 United Planet GmbH, Freiburg - All Rights Reserved.
 */
//////////////////////////////////////////////////////////////////////////////////////
//                                GESTURE-HANDLING                                  //
//////////////////////////////////////////////////////////////////////////////////////
/**
    @class GestureListener
    @private
*/
var GestureListener = {};
/**
    @methodOf GestureListener
    @private
    @param {Event} e
    @description
    Drag&Drop
    oDomElement.onmousedown = testEl.ontouchstart = GestureListener.addDrag;
*/
GestureListener.addDrag = function (e) {
    e.preventDefault();
    var oDomElement = e.target;
    if (e.type === "touchstart") {
        oDomElement.onmousedown = null;
        oDomElement.ontouchmove = moveDrag;
        oDomElement.ontouchend = function () {
            oDomElement.ontouchmove = null;
            oDomElement.ontouchend = null;
        };
    } else {
        document.onmousemove = moveDrag;
        document.onmouseup = function () {
            document.onmousemove = null;
            document.onmouseup = null;
        };
    }

    var pos = [oDomElement.offsetLeft, oDomElement.offsetTop];
    var origin = getCoors(e);
    return false; // cancels scrolling

    function moveDrag(e) {
        var currentPos = getCoors(e);
        var deltaX = currentPos[0] - origin[0];
        var deltaY = currentPos[1] - origin[1];

        oDomElement.style.left = pos[0] + deltaX + "px";
        oDomElement.style.top = pos[1] + deltaY + "px";
    }

    function getCoors(e) {
        var coors = [];
        if (e.touches && e.touches.length) {
            // iWebKit
            coors[0] = e.touches[0].clientX;
            coors[1] = e.touches[0].clientY;
        } else {
            // all others
            coors[0] = e.clientX;
            coors[1] = e.clientY;
        }
        return coors;
    }
};
/**
    @private
    @methodOf GestureListener
    @description
    You can identify a swipe gesture as follows:
    1. Begin gesture if you receive a touchstart event containing one target touch.
    2. Abort gesture if, at any time, you receive an event with >1 touches.
    3. Continue gesture if you receive a touchmove event mostly in the x-direction.
    4. Abort gesture if you receive a touchmove event mostly the y-direction.
    5. End gesture if you receive a touchend event.
   */
GestureListener.addSwipe = function (oDomElement, fnCallback) {
    var startX;
    var startY;
    var deltaX;
    var direction;

    function onTouchStart(e) {
        if (e.touches.length == 1) {
            startX = e.touches[0].pageX;
            startY = e.touches[0].pageY;
            oDomElement.addEventListener("touchmove", onTouchMove, false);
            oDomElement.addEventListener("touchend", onTouchEnd, false);
        }
    }
    function cancelTouch() {
        oDomElement.removeEventListener("touchmove", onTouchMove);
        oDomElement.removeEventListener("touchend", onTouchEnd);
        startX = null;
        startY = null;
        direction = null;
        return true;
    }
    function onTouchMove(e) {
        if (e.touches.length > 1) {
            cancelTouch();
        } else {
            deltaX = e.touches[0].pageX - startX;
            var deltaY = e.touches[0].pageY - startY;
            if (direction === null) {
                direction = deltaX;
            } else if (Math.abs(deltaY) < 50 && ((direction < 0 && deltaX < 0) || (direction > 0 && deltaX > 0))) {
                e.preventDefault();
            } else {
                cancelTouch();
            }
        }
    }
    function onTouchEnd() {
        cancelTouch();
        if (Math.abs(deltaX) > 50) {
            // remove listerner - appmain/stage is changed - a new FuncPart is loaded
            oDomElement.ontouchstart = null;
            fnCallback({ target: oDomElement, direction: deltaX > 0 ? "right" : "left" });
        }
    }
    oDomElement.ontouchstart = onTouchStart;
};
