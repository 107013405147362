/* eslint-disable no-useless-escape */
/* globals HelperMain, UpIntegerControl, UpCurrencyControl, UpFloatControl, UpDateTimeEditControl, UpDateEditControl, Browser, Helper, trim */
/* exported HelperMain, isOnElement */
/**
 * @file General helper functios for mobile devices
 * @copyright 2004-2016 United Planet GmbH, Freiburg - All Rights Reserved.
 */
//////////////////////////////////////////////////////////////////////////////////////
//                                STRING-OPERATIONS                                 //
//////////////////////////////////////////////////////////////////////////////////////
/**
 * @methodOf HelperMain
 * @public
 * @param {String} s JavaScript string
 * @return {String} output Encoded JavaScript string.
 * @description Encodes particular JavaScript characters.
 */
HelperMain.prototype.jsEncode = function (s) {
    var out = [];
    for (var i = 0; i < s.length; i++) {
        out.push(this._jsEncode(s.charAt(i)));
    }
    return out.join("");
};

/**
 * @private
 */
HelperMain.prototype._jsEncode = function (c) {
    var out_c = "";
    switch (c) {
        case "'":
            out_c = "\\'";
            break;
        case '"':
            out_c = '\\"';
            break;
        case "\\":
            out_c = "\\\\";
            break;
        case "\r":
            out_c = "\\r";
            break;
        case "\n":
            out_c = "\\n";
            break;
        default:
            out_c = c;
            break;
    }
    return out_c;
};

/**
 * @methodOf HelperMain
 * @private
 * @param {Object} p_oHtml given html element
 * @returns {Boolean}
 */
HelperMain.prototype.getInstanceOfElement = function (p_oHtml) {
    try {
        //Using W3 DOM2 (works for FF, Opera and Chrom)
        return p_oHtml instanceof HTMLElement;
    } catch (e) {
        //Browsers not supporting W3 DOM2 don't have HTMLElement and
        //an exception is thrown and we end up here. Testing some
        //properties that all elements have. (works on IE7)
        return (
            typeof p_oHtml === "object" &&
            p_oHtml.nodeType === 1 &&
            typeof p_oHtml.style === "object" &&
            typeof p_oHtml.ownerDocument === "object"
        );
    }
};

/**
 * @methodOf HelperMain
 * @public
 * @param {String} strValue Local formatted string.
 * @returns {String} Integer formatted string.
 * @description Parses a local formatted string.
 */
HelperMain.prototype.getIntegerStringByLocal = function (p_strValue, p_oFormat) {
    return new String(this.getIntegerByLocalString(p_strValue, p_oFormat));
};

/**
 * @methodOf HelperMain
 * @public
 * @deprecated
 * @param {Object} strValue Local formatted string.
 * @return {Number} Javascript number object.
 * @description Parses a local formatted string to number.
 */
HelperMain.prototype.getIntegerByLocalString = function (p_strValue, p_oFormat) {
    var integerControl = new UpIntegerControl($('<input type="text"/>')[0], p_oFormat);
    // compatibility to Intrexx 6.0
    if (typeof p_strValue === "string" && p_strValue === "") {
        return 0;
    } else {
        return integerControl.toJSNumber(p_strValue);
    }
};

/**
 * @methodOf HelperMain
 * @param {String} strValue Local formatted string.
 * @return {String} Currency formatted string.
 * @description Parses a local formatted string.
 */
HelperMain.prototype.getCurrencyStringByLocal = function (p_strValue, p_oFormat) {
    return new String(this.getCurrencyByLocalString(p_strValue, p_oFormat));
};

/**
 * @methodOf HelperMain
 * @public
 * @deprecated
 * @param {Object} strValue Local formatted string.
 * @return {Number} Javascript number object.
 * @description Parses a local formatted string to number.
 */
HelperMain.prototype.getCurrencyByLocalString = function (p_strValue, p_oFormat) {
    var currencyControl = new UpCurrencyControl($('<input type="text"/>')[0], p_oFormat);
    // compatibility to Intrexx 6.0
    if (typeof p_strValue === "string" && p_strValue === "") {
        return 0;
    } else {
        return currencyControl.toJSNumber(p_strValue);
    }
};

/**
 * @methodOf HelperMain
 * @public
 * @param {String} strValue Local formatted string.
 * @return {String} Currency formatted string.
 * @description Parses a local formatted string.
 */
HelperMain.prototype.getFloatStringByLocal = function (p_strValue, p_oFormat) {
    return new String(this.getFloatByLocalString(p_strValue, p_oFormat));
};

/**
 * @methodOf HelperMain
 * @public
 * @deprecated
 * @param {Object} strValue Local formatted string.
 * @return {Number}
 * @description Parses a local formatted string to number.
 */
HelperMain.prototype.getFloatByLocalString = function (p_strValue, p_oFormat) {
    var floatControl = new UpFloatControl($('<input type="text"/>')[0], p_oFormat);
    // compatibility to Intrexx 6.0
    if (typeof p_strValue === "string" && p_strValue === "") {
        return 0;
    } else {
        return floatControl.toJSNumber(p_strValue);
    }
};

/**
 * @methodOf HelperMain
 * @public
 * @param {String} strValue Local formatted string.
 * @return {Date} javascript date object.
 * @description Parses a local formatted date-time string.
 */
HelperMain.prototype.getDateByLocalDateTimeString = function (p_strValue, p_oFormat) {
    var oInt = new UpDateTimeEditControl($('<input type="text"/>')[0], p_oFormat);
    return oInt.setDateObjectFromLocal(p_strValue);
};

/**
 * @methodOf HelperMain
 * @public
 * @param {String} strValue Local formatted string.
 * @return {Date}
 * @description Parses a local formatted date string.
 */
HelperMain.prototype.getDateByLocalDateString = function (p_strValue, p_oFormat) {
    var oInt = new UpDateEditControl($('<input type="text"/>')[0], p_oFormat);
    return oInt.setDateObjectFromLocal(p_strValue);
};

/**
 * @description Creates an JavaScript date object from a html control.
 * @param {Object} oHtml Reference to a html input control of types "date", "time" or "date & time".
 * @return {Date}
 */
HelperMain.prototype.getDateObject = function (oHtml) {
    if (!oHtml || !oHtml.oUp || oHtml.value === "") {
        return null;
    }
    return oHtml.oUp.setDateObjectFromLocal(oHtml.value);
};

/**
 * @decription Creates an JavaScript number object from a html control.
 * @param {Object} oHtml Reference to a html input control of types "integer", "float" or "currency".
 * @return {Number}
 */
HelperMain.prototype.getNumberObject = function (oHtml) {
    if (!oHtml || !oHtml.oUp || oHtml.value === "") {
        return null;
    }
    return oHtml.oUp.toJSNumber(oHtml.value);
};

/**
 * @description Parses a iso formatted string.
 * @param {String} strValue iso formatted string.
 * @return {Date}
 */
HelperMain.prototype.getDateByIsoString = function (strValue) {
    var oDateTime = new UpDateTimeEditControl($('<input type="text"/>')[0]);
    return oDateTime.setDateObjectFromISO(strValue);
};

/**
 * @description Creates a local formatted string from an number string or jsDate.
 * @param {Object} oHtml Reference to a html input control of types "integer", "float", "currency" (format settings can be appended here, too).
 * @param {Object} value Number string or JavaScript date object.
 * @return {String} Local formatted number as string
 */
HelperMain.prototype.toLocalString = function (oHtml, value) {
    if (arguments.length < 2 || !oHtml || !oHtml.oUp) {
        return null;
    }
    return oHtml.oUp.toLocalFormat(value);
};

/**
 * @methodOf HelperMain
 * @param {Object} p_oHtmlInput Html input control (getElement("<GUID>"))
 * @param {Boolean} p_bOverwrite <code>true</code> if p_oHtmlInput should be replaced with current date
 * @return {Boolean}
 * @description Sets current date as local formatted string.
 */
HelperMain.prototype.setCurrentDate = function (p_oHtmlInput, p_bOverwrite) {
    if (typeof p_oHtmlInput !== "undefined" && typeof p_oHtmlInput.oUp === "object") {
        if (p_bOverwrite || (!p_bOverwrite && p_oHtmlInput.value === "")) {
            var el = p_oHtmlInput.oUp;
            Browser.setValue(el, el.toLocalFormat(new Date()), null, false);
        }
    }
    return true;
};

/**
 * @methodOf HelperMain
 * @private
 * @param {Object} p_oFieldFrom reference to the 'from' field
 * @param {Object} p_oFieldTo reference to the 'to' field
 * @param {Number} p_lMilli Time in ms to add
 * @return {Boolean}
 * @description Sets a time difference between two date controls, changes 'to' control
 */
HelperMain.prototype.setDateTo = function (p_oFieldFrom, p_oFieldTo, p_lMilli) {
    p_oFieldFrom.oUp.format();
    var l_lMilli;
    if (arguments.length === 3 && !isNaN(p_lMilli)) {
        l_lMilli = p_lMilli;
    } else {
        l_lMilli = p_oFieldFrom.oUp.upType === "upDateControl" ? 86400000 : 3600000;
    }
    var dtFrom = p_oFieldFrom.oUp.setDateObjectFromLocal(p_oFieldFrom.value);
    var dtTo = p_oFieldTo.oUp.setDateObjectFromLocal(p_oFieldTo.value);
    if (p_oFieldFrom.value !== "" && (p_oFieldTo.value === "" || dtFrom > dtTo)) {
        p_oFieldTo.setValue(new Date(dtFrom.getTime() + l_lMilli), { triggerOnchange: "strict" });
    }
    return true;
};

/**
 * @methodOf HelperMain
 * @private
 * @param {Object} p_oFieldFrom reference to the 'from' field
 * @param {Object} p_oFieldTo reference to the 'to' field
 * @param {Number} p_lMilli Time in ms to subtract
 * @return {Boolean}
 * @description Sets a time difference between two date controls, changes 'from' control
 */
HelperMain.prototype.setDateFrom = function (p_oFieldFrom, p_oFieldTo, p_lMilli) {
    p_oFieldTo.oUp.format();
    var l_lMilli;
    if (arguments.length === 3 && !isNaN(p_lMilli)) {
        l_lMilli = p_lMilli;
    } else {
        l_lMilli = p_oFieldFrom.oUp.upType === "upDateControl" ? 86400000 : 1800000;
    }
    var dtFrom = p_oFieldFrom.oUp.setDateObjectFromLocal(p_oFieldFrom.value);
    var dtTo = p_oFieldTo.oUp.setDateObjectFromLocal(p_oFieldTo.value);

    if (p_oFieldTo.value !== "" && (p_oFieldFrom.value === "" || dtFrom > dtTo)) {
        p_oFieldFrom.setValue(new Date(dtTo.getTime() - l_lMilli), { triggerOnchange: "strict" });
    }
    return true;
};

/**
 * @methodOf HelperMain
 * @private
 * @param {Object} p_oFieldFrom Start date
 * @param {Object} p_oFieldTo End date
 * @param {Boolean} p_bMsg If <code>false</code> the alert is suppressed.
 * @return {Boolean} valid <code>true</code>If first date lies before second date.
 * @description Compares start and end date, alert if start date is after end date
 */
HelperMain.prototype.validateFromTo = function (p_oFieldFrom, p_oFieldTo, p_bMsg) {
    var l_bRet = true;
    if (arguments.length < 3) {
        p_bMsg = true;
    }
    if (p_oFieldFrom !== null && p_oFieldTo !== null && p_oFieldFrom.oUp !== null && p_oFieldTo.oUp !== null) {
        if (this.compareValues(p_oFieldFrom, p_oFieldTo) === 2) {
            if (p_bMsg) {
                alert(self.oUp.oMessage.CAL_ERR_START_GREATER_THAN_END);
            }
            l_bRet = false;
        }
    }
    return l_bRet;
};

/**
 * @methodOf HelperMain
 * @private
 * @param {Object} p_oDate Date object
 * @return 0 if current date > date, 1 if current date = date, 2 if current date < date
 * @description
    Compares a given date with the current date
*/
HelperMain.prototype.dateComparedToNow = function (p_oDate) {
    var l_ret = -1;
    var dCurrentDate;
    var l_strDate;
    if (p_oDate.oHtml) {
        p_oDate = p_oDate.oHtml;
    }
    if (
        p_oDate &&
        p_oDate.oUp &&
        (p_oDate.oUp.upType === "upDateTimeControl" ||
            p_oDate.oUp.upType === "upDateTimeVControl" ||
            p_oDate.oUp.upType === "upDateControl" ||
            p_oDate.oUp.upType === "upDateVControl" ||
            p_oDate.oUp.upType === "upTimeControl" ||
            p_oDate.oUp.upType === "upTimeControl")
    ) {
        if (
            p_oDate.oUp.upType === "upDateTimeVControl" ||
            p_oDate.oUp.upType === "upDateVControl" ||
            p_oDate.oUp.upType === "upTimeControl"
        ) {
            dCurrentDate = p_oDate.oUp.setDateObjectFromLocal();
        } else {
            dCurrentDate = p_oDate.oUp.setDateObjectFromLocal(p_oDate.oUp.toLocalFormat(new Date()));
        }
        l_strDate = trim(Browser.getValue(p_oDate.oUp));
        var dInputDate = p_oDate.oUp.setDateObjectFromLocal(l_strDate);

        if (Date.parse(dCurrentDate) > Date.parse(dInputDate)) {
            l_ret = 0;
        } else if (Date.parse(dCurrentDate) < Date.parse(dInputDate)) {
            l_ret = 2;
        } else if (Date.parse(dCurrentDate) === Date.parse(dInputDate)) {
            l_ret = 1;
        }
    }
    return l_ret;
};

/**
 * @methodOf HelperMain
 * @private
 * @param {String} p_strInterval Interval unit
 * @param {Integer} p_iNum Num Interval to add
 * @param {Object} p_oDate Date object
 * @return {Date}
 * @description Adds a time interval to a given date
 */
HelperMain.prototype.dateAdd = function (p_strInterval, p_iNum, p_oDate) {
    if (typeof p_oDate !== "object") {
        return new Date();
    }
    if (!p_strInterval || p_iNum === 0) {
        return p_oDate;
    }

    var dtTmp = new Date(p_oDate);

    switch (p_strInterval.toLowerCase()) {
        case "ms":
            dtTmp.setMilliseconds(dtTmp.getMilliseconds() + p_iNum);
            break;
        case "s":
            dtTmp.setSeconds(dtTmp.getSeconds() + p_iNum);
            break;
        case "mi":
            dtTmp.setMinutes(dtTmp.getMinutes() + p_iNum);
            break;
        case "h":
            dtTmp.setHours(dtTmp.getHours() + p_iNum);
            break;
        case "d":
            dtTmp.setDate(dtTmp.getDate() + p_iNum);
            break;
        case "mo":
            dtTmp.setMonth(dtTmp.getMonth() + p_iNum);
            break;
        case "y":
            dtTmp.setFullYear(dtTmp.getFullYear() + p_iNum);
            break;
        default:
            break;
    }
    return dtTmp;
};

/**
 * @private
 * @methodOf HelperMain
 * @param {String} strIn String
 * @return {String} output Regular expression pattern.
 * @description Creates a pattern for regular expressions from a string.
 */
HelperMain.prototype.makeRegExpPattern = function (strIn) {
    var pattern = [];
    var c = "";
    if (typeof strIn === "undefined" || strIn === "") {
        return "";
    }
    for (var i = 0; i < strIn.length; i++) {
        c = strIn.substr(i, 1);
        if (
            c === "-" ||
            c === "^" ||
            c === "\\" ||
            c === "$" ||
            c === "*" ||
            c === "." ||
            c === "+" ||
            c === "[" ||
            c === "]" ||
            c === "|"
        ) {
            c = "\\" + c;
        }
        pattern.push(c);
    }
    return "^[" + pattern.join("") + "]*$";
};
/**
 * @methodOf HelperMain
 * @private
 * @description escapes a regexp pattern for use as parameter in regexp constructor. reserved characters: ^$.*+?=!:|\/()[]{}
 * @param {String} strPattern String
 * @return {String} escaped regular expression string
 */
HelperMain.prototype.toRegExpString = function (strPattern) {
    return strPattern.replace(/([\^\$\.\*\+\?\=\!\:\|\\\/\(\)\[\]\{\}])/g, "\\$1");
};
/**
 * @methodOf HelperMain
 * @private
 * @description Decodes particular XML characters.
 * @param {String} s XML string
 * @return {Object} s Decoded XML string.
 */
HelperMain.prototype.xmlDecode = function (s) {
    s = s.replace(/&quot;/g, '"');
    s = s.replace(/&gt;/g, ">");
    s = s.replace(/&lt;/g, "<");
    s = s.replace(/&#x27;/g, "'");
    s = s.replace(/&amp;/g, "&");
    return s;
};

//////////////////////////////////////////////////////////////////////////////////////
//                                MEDIA-CONTROL                                     //
//////////////////////////////////////////////////////////////////////////////////////
/**
 * @methodOf HelperMain
 * @private
 * @param {HTMLElement} p_oHtml the given text element
 * @param {HTMLElement} p_oHiddenField
 * @description call apis of youtube, vimeo and dailymotion. handle video, image and normal urls. put video informations to json
 */
HelperMain.prototype.videoJSON = function (p_oHtml, p_oHiddenField) {
    var l_oHtml = p_oHtml,
        l_oHiddenField = p_oHiddenField,
        agent = navigator.userAgent,
        isWindowsMobile = agent.indexOf("Windows Phone") > 0,
        isOperaMobile = agent.indexOf("Opera Mobi") > 0;

    $.valHooks.textarea = {
        get: function (p_oHtml) {
            return p_oHtml.value.replace(/\r?\n/g, "\r\n");
        },
    };
    var l_strValue = $(l_oHtml).val();
    var l_patternUrl =
            /(\s>|https?:\/\/|ftp:\/\/)(?:www\.)?[-A-Z0-9+$&@#\/%?=~_|!:,.;\(\)\*\'\[\]]*[-A-Z0-9+&@#\/%=~_|\]]/gim,
        l_patternYT =
            /^http[s]?:\/\/(?:www\.)?youtu(?:(?:\.be\/)|(?:be(?:-nocookie|\.googleapis)?\.com)\/(?:(?:(?:embed|v)\/)|(?:watch\?v=)))([a-zA-Z0-9\-_]*)/,
        l_patternYT2 = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/ ]{11})/i,
        l_patternMyV = /^http[s]?:\/\/(?:www\.)?myvideo\.de\/(?:(?:watch)|(?:movie))\/([0-9]*)/,
        l_patternVimeo =
            /^http[s]?:\/\/(?:www\.)?(?:player\.)?vimeo\.com\/(?:video\/)?(?:moogaloop\.swf\?clip_id=)?([0-9]*)/,
        l_patternDM = /(^http[s]?:\/\/(?:www\.)?dailymotion\.com\/(?:(?:video))|dai\.ly)\/([a-zA-Z0-9\-_]*)/,
        l_aJSON = [],
        l_strFormat = "",
        l_strNoAPI = "noApi",
        l_bYtFeatureLink = false;

    var l_aUrls, match, l_strPositionStart, l_strPositionEnd, id, l_strUrlAPI, l_strUrl, l_strType, laenge;

    if (!l_strValue.match(l_patternUrl)) {
        $(l_oHiddenField).val("");
        return true;
    } else {
        l_aUrls = l_strValue.match(l_patternUrl);
    }

    if (isWindowsMobile || isOperaMobile) {
        l_patternUrl = /(\s>|https?:\/\/|ftp:\/\/)(?:www\.)?[-A-Z0-9+&@#\/%?=~_|!:,.;\(\)\*\']*[-A-Z0-9+&@#\/%=~_|]/gim;
    }

    $.each(l_aUrls, function (i) {
        var l_strVideoYT = l_aUrls[i].match(l_patternYT) ? l_aUrls[i].match(l_patternYT)[1] : null,
            l_strVideoMyV = l_aUrls[i].match(l_patternMyV) ? l_aUrls[i].match(l_patternMyV)[1] : null,
            l_strVideoVimeo = l_aUrls[i].match(l_patternVimeo) ? l_aUrls[i].match(l_patternVimeo)[1] : null,
            l_strVideoDM = l_aUrls[i].match(l_patternDM) ? l_aUrls[i].match(l_patternDM)[2] : null;

        if (!l_strVideoYT) {
            l_strVideoYT = l_aUrls[i].match(l_patternYT2) ? l_aUrls[i].match(l_patternYT2)[1] : null;
            l_bYtFeatureLink = true;
        }
        var l_strTextUrl;
        if (l_strVideoYT) {
            match = l_patternUrl.exec(l_strValue);
            l_strTextUrl = match[0];
            l_strPositionStart = match.index;
            l_strPositionEnd = l_patternUrl.lastIndex;

            if (!l_bYtFeatureLink) {
                id = l_aUrls[i].match(l_patternYT)[1];
            } else {
                id = l_strVideoYT;
            }

            if (location.protocol === "https:") {
                l_strUrlAPI = 'https://gdata.youtube.com/feeds/api/videos?q="' + id + '"&max-results=1&v=2&alt=jsonc';
            } else {
                l_strUrlAPI = 'http://gdata.youtube.com/feeds/api/videos?q="' + id + '"&max-results=1&v=2&alt=jsonc';
            }

            l_strFormat = "youtube";

            _createJSON(l_strTextUrl, id, l_strPositionStart, l_strPositionEnd, l_strFormat);
        } else if (l_strVideoVimeo) {
            match = l_patternUrl.exec(l_strValue);
            l_strTextUrl = match[0];
            l_strPositionStart = match.index;
            l_strPositionEnd = l_patternUrl.lastIndex;

            id = l_aUrls[i].match(l_patternVimeo)[1];
            if (location.protocol === "https:") {
                l_strUrlAPI = "https://vimeo.com/api/oembed.json?url=http%3A//vimeo.com/" + id + "";
            } else {
                l_strUrlAPI = "https://vimeo.com/api/oembed.json?url=http%3A//vimeo.com/" + id + "";
            }

            l_strFormat = "vimeo";
            _getJSON(l_strTextUrl, l_strUrlAPI, l_strPositionStart, l_strPositionEnd, l_strFormat);
        } else if (l_strVideoDM) {
            match = l_patternUrl.exec(l_strValue);
            l_strTextUrl = match[0];
            l_strPositionStart = match.index;
            l_strPositionEnd = l_patternUrl.lastIndex;

            id = l_aUrls[i].match(l_patternDM)[2];
            l_strUrlAPI =
                "https://api.dailymotion.com/video/" + id + "?fields=title,duration,user,thumbnail_url,url,id,tags";
            l_strFormat = "dailymotion";

            _getJSON(l_strTextUrl, l_strUrlAPI, l_strPositionStart, l_strPositionEnd, l_strFormat);
        } else if (l_strVideoMyV) {
            match = l_patternUrl.exec(l_strValue);
            l_strTextUrl = match[0];
            l_strPositionStart = match.index;
            l_strPositionEnd = l_patternUrl.lastIndex;

            id = l_aUrls[i].match(l_patternMyV)[1];
            l_strUrl = l_aUrls[i].match(l_patternMyV)[0];
            l_strFormat = "myvideo";
            l_strType = "video";

            _createJSON(
                l_strTextUrl,
                l_strNoAPI,
                l_strPositionStart,
                l_strPositionEnd,
                l_strFormat,
                l_strType,
                l_strUrl,
                id
            );
        } else {
            match = l_patternUrl.exec(l_strValue);
            l_strTextUrl = match[0];
            l_strPositionStart = match.index;
            l_strPositionEnd = l_patternUrl.lastIndex;

            var splitUrl = l_aUrls[i].split("."),
                mime = splitUrl.pop();

            switch (mime) {
                case "swf": // fall through
                case "mp4": // fall through
                case "ogg": // fall through
                case "ogv": // fall through
                case "oga": // fall through
                case "ogx": // fall through
                case "webm": // fall through
                case "mov":
                    l_strType = "video";
                    l_strFormat = mime;
                    break;
                case "jpg": // fall through
                case "jpeg": // fall through
                case "png": // fall through
                case "gif": // fall through
                case "bmp":
                    var l_strPatternCheckUrl =
                        /(\.php|\.php4|\.php5|\.phtml|\.asp|\.aspx|\.jsp|\.csp|\.cfm|\.ssjs|\.cgi|\.ipl|\.pl|\.py|\.rb|\.rbw|\.dna|\.tpl)/i;
                    if (l_strTextUrl.match(l_strPatternCheckUrl)) {
                        l_strType = "url";
                        l_strFormat = "url";
                    } else {
                        l_strType = "image";
                        l_strFormat = mime;
                    }
                    break;
                case "js":
                    l_strType = "javascript";
                    l_strFormat = mime;
                    break;
                default:
                    l_strFormat = "url";
                    l_strType = "url";
                    break;
            }
            _createJSON(
                l_strTextUrl,
                l_strNoAPI,
                l_strPositionStart,
                l_strPositionEnd,
                l_strFormat,
                l_strType,
                l_aUrls[i]
            );
        }
    });

    function _getJSON(p_strTextUrl, p_url, p_strPositionStart, p_strPositionEnd, p_strFormat) {
        var strJsonData = "json";
        $.ajax({
            url: p_url,
            async: false,
            dataType: strJsonData,
            type: "GET",
        })
            .done(function (p_oResponse) {
                if (!p_oResponse.data) {
                    _createJSON(p_strTextUrl, p_oResponse, p_strPositionStart, p_strPositionEnd, p_strFormat);
                } else {
                    if (p_oResponse.data.totalItems !== 0) {
                        if (id !== p_oResponse.data.items[0].id) {
                            _createJSON(
                                p_strTextUrl,
                                "noApi",
                                p_strPositionStart,
                                p_strPositionEnd,
                                "url",
                                "url",
                                p_strTextUrl
                            );
                        } else {
                            _createJSON(p_strTextUrl, p_oResponse, p_strPositionStart, p_strPositionEnd, p_strFormat);
                        }
                    } else {
                        _createJSON(
                            p_strTextUrl,
                            "noApi",
                            p_strPositionStart,
                            p_strPositionEnd,
                            "url",
                            "url",
                            p_strTextUrl
                        );
                    }
                }
            })
            .fail(function (error) {
                console.log(error);
                return false;
            });
    }

    function _createJSON(
        p_strTextUrl,
        p_oResponse,
        p_strPositionStart,
        p_strPositionEnd,
        p_strFormat,
        p_strType,
        p_strUrl,
        p_strId
    ) {
        var l_strVideoThumbNailLow;
        var l_strVideoThumbNailHigh;
        var l_strUrl;
        var l_strVideoId;
        var l_strVideoTitle;
        var strJSONFinal;

        if (p_oResponse !== "noApi") {
            strJSONFinal = '{"url":[';

            if (l_aJSON.length !== 0) {
                strJSONFinal.slice(0, 8);
            }

            if (!p_oResponse.data) {
                l_strVideoThumbNailLow = p_oResponse.thumbnail_url;
                l_strVideoThumbNailHigh = p_oResponse.thumbnail_url;
                if (p_strFormat === "vimeo") {
                    l_strUrl = p_oResponse.provider_url + p_oResponse.video_id;
                    l_strVideoId = p_oResponse.video_id;

                    if (p_oResponse.title !== undefined) {
                        l_strVideoTitle = Helper.jsEncode(p_oResponse.title);
                    }
                } else if (p_strFormat === "dailymotion") {
                    l_strUrl = p_oResponse.url;
                    l_strVideoId = p_oResponse.id;

                    if (p_oResponse.title !== undefined) {
                        l_strVideoTitle = Helper.jsEncode(p_oResponse.title);
                    }
                } else if (p_strFormat === "youtube") {
                    l_strVideoId = p_oResponse;
                    l_strVideoTitle = "YouTube Video";
                    l_strVideoThumbNailLow = "http://img.youtube.com/vi/" + l_strVideoId + "/default.jpg";
                    l_strVideoThumbNailHigh = "http://img.youtube.com/vi/" + l_strVideoId + "/hqdefault.jpg";
                    l_strUrl = "http://www.youtube.com/watch?v=" + l_strVideoId;
                }
            }

            l_aJSON.push("{");
            l_aJSON.push('"type":"video",');
            l_aJSON.push('"format":"' + p_strFormat + '",');
            l_aJSON.push('"title":"' + l_strVideoTitle + '",');
            l_aJSON.push('"id":"' + l_strVideoId + '",');
            l_aJSON.push('"url":"' + l_strUrl + '",');
            l_aJSON.push('"thumbnailHighQuality":"' + l_strVideoThumbNailHigh + '",');
            l_aJSON.push('"thumbnailLowQuality":"' + l_strVideoThumbNailLow + '",');
            l_aJSON.push('"startPoint":"' + p_strPositionStart + '",');
            l_aJSON.push('"endPoint":"' + p_strPositionEnd + '",');
            l_aJSON.push('"textUrl":"' + p_strTextUrl + '"');
            l_aJSON.push("},");

            for (var i = 0; i <= l_aJSON.length - 1; i++) {
                strJSONFinal = strJSONFinal + l_aJSON[i];
            }

            laenge = strJSONFinal.length;

            strJSONFinal = strJSONFinal.slice(0, laenge - 1);
            strJSONFinal = strJSONFinal + "]}";

            $(l_oHiddenField).val(strJSONFinal);
            p_strUrl = "";
            return;
        } else {
            strJSONFinal = '{"url":[';

            if (l_aJSON.length !== 0) {
                strJSONFinal.slice(0, 8);
            }

            l_aJSON.push("{");
            l_aJSON.push('"type":"' + p_strType + '",');
            l_aJSON.push('"format":"' + p_strFormat + '",');
            l_aJSON.push('"title":"",');
            if (p_strId !== "") {
                l_aJSON.push('"id":"' + p_strId + '",');
            } else {
                l_aJSON.push('"id":"",');
            }
            l_aJSON.push('"url":"' + p_strUrl + '",');
            l_aJSON.push('"thumbnailHighQuality":"",');
            l_aJSON.push('"thumbnailLowQuality":"",');
            l_aJSON.push('"startPoint":"' + p_strPositionStart + '",');
            l_aJSON.push('"endPoint":"' + p_strPositionEnd + '",');
            l_aJSON.push('"textUrl":"' + p_strTextUrl + '"');
            l_aJSON.push("},");

            for (var j = 0; j <= l_aJSON.length - 1; j++) {
                strJSONFinal = strJSONFinal + l_aJSON[j];
            }

            laenge = strJSONFinal.length;

            strJSONFinal = strJSONFinal.slice(0, laenge - 1);
            strJSONFinal = strJSONFinal + "]}";

            $(l_oHiddenField).val(strJSONFinal);
            return true;
        }
    }
    return true;
};

/**
 * @private
 * embedded video, image and website urls
 * @param {object} p_oHtml Reference to a div element with data-video attribute
 */
HelperMain.prototype.videoEmbed = function (p_oHtml) {
    var inputUrlVal = p_oHtml.text(),
        htmlMarkUp = "",
        elToAppend = p_oHtml;

    if (!inputUrlVal) return false;

    var vPortYT = inputUrlVal.search(
            /^http[s]?:\/\/(?:www\.)?youtu(?:(?:\.be\/)|(?:be(?:-nocookie|\.googleapis)?\.com)\/(?:(?:(?:embed|v)\/)|(?:watch\?v=)))([a-zA-Z0-9\-_]*)/
        ),
        vPortMyV = inputUrlVal.search(/^http[s]?:\/\/(?:www\.)?myvideo\.de\/(?:(?:watch)|(?:movie))\/([0-9]*)/),
        vPortVimeo = inputUrlVal.search(
            /^http[s]?:\/\/(?:www\.)?(?:player\.)?vimeo\.com\/(?:video\/)?(?:moogaloop\.swf\?clip_id=)?([0-9]*)/
        ),
        vPortDailyMotion = inputUrlVal.search(
            /(^http[s]?:\/\/(?:www\.)?dailymotion\.com\/(?:(?:video)))\/([a-zA-Z0-9\-_]*)/
        );

    if (vPortYT != -1) {
        if (
            inputUrlVal.match(
                /^http[s]?:\/\/(?:www\.)?youtu(?:(?:\.be\/)|(?:be(?:-nocookie|\.googleapis)?\.com)\/(?:(?:(?:embed|v)\/)|(?:watch\?v=)))([a-zA-Z0-9\-_]*)/
            )[1]
        ) {
            var strVideoId = inputUrlVal.match(
                /^http[s]?:\/\/(?:www\.)?youtu(?:(?:\.be\/)|(?:be(?:-nocookie|\.googleapis)?\.com)\/(?:(?:(?:embed|v)\/)|(?:watch\?v=)))([a-zA-Z0-9\-_]*)/
            )[1];
            if (location.protocol === "https:") {
                htmlMarkUp =
                    "<iFrame src='https://www.youtube.com/embed/" +
                    strVideoId +
                    "' frameborder='0' style='width:inherit;height:inherhit;' allowfullscreen></iFrame>";
            } else {
                htmlMarkUp =
                    "<iFrame src='http://www.youtube.com/embed/" +
                    strVideoId +
                    "' frameborder='0' style='width:inherit;height:inherhit;' allowfullscreen></iFrame>";
            }
            $(elToAppend).text("");
            $(elToAppend).append(htmlMarkUp);
        }
    } else if (vPortMyV != -1) {
        if (inputUrlVal.match(/^http[s]?:\/\/(?:www\.)?myvideo\.de\/(?:(?:watch)|(?:movie))\/([0-9]*)/)[1]) {
            strVideoId = inputUrlVal.match(/^http[s]?:\/\/(?:www\.)?myvideo\.de\/(?:(?:watch)|(?:movie))\/([0-9]*)/)[1];
            htmlMarkUp =
                "<iFrame src='http://www.myvideo.de/embed/" +
                strVideoId +
                "' style='width:inherit;height:inherhit;border:0px none;padding:0;margin:0;' frameborder='0' scrolling='no'></iFrame>";
            $(elToAppend).text("");
            $(elToAppend).append(htmlMarkUp);
        }
    } else if (vPortVimeo != -1) {
        if (
            inputUrlVal.match(
                /^http[s]?:\/\/(?:www\.)?(?:player\.)?vimeo\.com\/(?:video\/)?(?:moogaloop\.swf\?clip_id=)?([0-9]*)/
            )[1]
        ) {
            strVideoId = inputUrlVal.match(
                /^http[s]?:\/\/(?:www\.)?(?:player\.)?vimeo\.com\/(?:video\/)?(?:moogaloop\.swf\?clip_id=)?([0-9]*)/
            )[1];
            if (location.protocol === "https:") {
                htmlMarkUp =
                    "<iFrame src='https://player.vimeo.com/video/" +
                    strVideoId +
                    "' frameborder='0' style='width:inherit;height:inherhit;' webkitAllowFullScreen mozallowfullscreen allowFullScreen></iFrame>";
            } else {
                htmlMarkUp =
                    "<iFrame src='http://player.vimeo.com/video/" +
                    strVideoId +
                    "' frameborder='0' style='width:inherit;height:inherhit;' webkitAllowFullScreen mozallowfullscreen allowFullScreen></iFrame>";
            }
            $(elToAppend).text("");
            $(elToAppend).append(htmlMarkUp);
        }
    } else if (vPortDailyMotion != -1) {
        if (inputUrlVal.match(/(^http[s]?:\/\/(?:www\.)?dailymotion\.com\/(?:(?:video)))\/([a-zA-Z0-9\-_]*)/)[2]) {
            strVideoId = inputUrlVal.match(
                /(^http[s]?:\/\/(?:www\.)?dailymotion\.com\/(?:(?:video)))\/([a-zA-Z0-9\-_]*)/
            )[2];
            htmlMarkUp =
                "<iframe frameborder='0' style='width:inherit;height:inherhit;' src='http://www.dailymotion.com/embed/video/" +
                strVideoId +
                "'></iframe>";
            $(elToAppend).text("");
            $(elToAppend).append(htmlMarkUp);
        }
    } else {
        var splitUrl = inputUrlVal.split(".");
        var lastArrEl = splitUrl.pop();

        lastArrEl = lastArrEl.toLowerCase();

        if (lastArrEl === "swf") {
            htmlMarkUp =
                "<object type='application/x-shockwave-flash' style='width:inherit;height:inherhit;' data='" +
                inputUrlVal +
                "'>";
            htmlMarkUp = htmlMarkUp + "<param name='movie' value='" + inputUrlVal + "'>";
            htmlMarkUp = htmlMarkUp + "<param name='FlashVars' value='playerMode=embedded'>";
            htmlMarkUp = htmlMarkUp + "<param name='quality' value='best'>";
            htmlMarkUp = htmlMarkUp + "<param name='wmode' value='transparent'>";
            htmlMarkUp = htmlMarkUp + "</object>";
            $(elToAppend).text("");
            $(elToAppend).append(htmlMarkUp);
        } else if (lastArrEl === "mp4" || lastArrEl.match(/ogg|oga|ogv|ogx/) || lastArrEl === "webm") {
            htmlMarkUp = "<video controls='controls' preload='auto' style='width:inherit;height:inherhit;'>";
            htmlMarkUp = htmlMarkUp + '<source src="' + inputUrlVal + '" type="video/' + lastArrEl + '">';
            htmlMarkUp =
                htmlMarkUp +
                "<p class='noSupport'>" +
                self.oUp.oMessage.VIDEO_NO_SUPPORT +
                "<a href=" +
                inputUrlVal +
                ">" +
                self.oUp.oMessage.VIDEO_DOWNLOAD +
                "</a></p>";
            htmlMarkUp = htmlMarkUp + "</video>";
            $(elToAppend).text("");
            $(elToAppend).append(htmlMarkUp);
        } else if (lastArrEl === "mov") {
            htmlMarkUp =
                "<object classid='clsid:02BF25D5-8C17-4B23-BC80-D3488ABDDC6B' style='width:inherit;height:inherhit;' codebase='http://www.apple.com/qtactivex/qtplugin.cab'>";
            htmlMarkUp = htmlMarkUp + "<param name='src' value='" + inputUrlVal + "'>";
            htmlMarkUp = htmlMarkUp + "<param name='autoplay' value='false'>";
            htmlMarkUp = htmlMarkUp + "<param name='type' value='video/quicktime'>";
            htmlMarkUp =
                htmlMarkUp +
                "<embed src='" +
                inputUrlVal +
                "' style='width:inherit;height:inherhit;' autoplay='false' type='video/quicktime' pluginspage='http://www.apple.com/quicktime/download/'>";
            htmlMarkUp = htmlMarkUp + "</object>";
            $(elToAppend).text("");
            $(elToAppend).append(htmlMarkUp);
        } else if (
            lastArrEl === "jpg" ||
            lastArrEl === "jpeg" ||
            lastArrEl === "gif" ||
            lastArrEl === "png" ||
            lastArrEl === "bmp"
        ) {
            htmlMarkUp =
                '<img src="' +
                inputUrlVal +
                '" class="Share_Container_Image_Border_3" style="width:inherit;height:inherhit;" />';
            $(elToAppend).text("");
            $(elToAppend).append(htmlMarkUp);
        } else if (lastArrEl === "js") {
            htmlMarkUp = inputUrlVal;
            $(elToAppend).removeAttr("class").text("").append(htmlMarkUp);
        }
    }
    return true;
};

/**
    @name isOnElement
    @function
    @private
    @param {JQuery-Object|HTMLElement}
        the Element which coordinates are to be checked, can be a jQuery Object or a DOM Node
    @param {Integer|Event}
        If only two Arguments are passed, the second Argument is assumed to be an event (JQuery or native).
        Else this second argument is used to represent the x coordinates of the event
    @param {Integer}
        if present, this argument is used as the y coordinate of the event
    @description
        checks if an event was fired in the rage of an elements coordinates.
    @returns {Boolean}
        true, if the events coordinates are whithin the elements coordinates
    @example
    <pre>
        1:
            $(document).on("click", function(e){
                if(isOnElement($('#div1'), e){
                    alert("on Element");
                }
            });
        2:
            document.body.addEventListener('click', function(e){
                if(isOnElement(document.getElementById("div1"), e.pageX, e.pageY)){
                    alert("on Element");
                }
            },false);
    </pre>
*/
function isOnElement(p_el, p_x, p_y) {
    var el, e, x, y;
    if (arguments.length >= 2) {
        el = !(p_el instanceof jQuery) ? $(p_el) : p_el; // wrap DOM Node to jQuery Object, if necessary
        if (arguments.length == 2) {
            if (p_x !== "undefined") {
                e = !(p_x instanceof jQuery.Event) ? jQuery.Event(p_x) : p_x; // wrap native event to jQuery Event, if necessary
                // x = parseInt( (typeof e.originalEvent.pageX !== "undefined" && !!e.originalEvent.pageX)?e.originalEvent.pageX:e.originalEvent.touches[0].pageX, 10 );   // check, if e.pageX is defined, else
                // y = parseInt( (typeof e.originalEvent.pageY !== "undefined" && !!e.originalEvent.pageY)?e.originalEvent.pageY:e.originalEvent.touches[0].pageY, 10 );   // assume touch event
                if (
                    typeof e.originalEvent !== "undefined" &&
                    typeof e.originalEvent.touches !== "undefined" &&
                    typeof e.originalEvent.touches[0] !== "undefined" &&
                    typeof e.originalEvent.touches[0].pageX !== "undefined"
                ) {
                    x = parseInt(e.originalEvent.touches[0].pageX, 10);
                } else if (
                    typeof e.originalEvent !== "undefined" &&
                    typeof e.originalEvent.pageX !== "undefined" &&
                    e.originalEvent.pageX !== null
                ) {
                    x = parseInt(e.originalEvent.pageX, 10);
                } else if (typeof e.pageX !== "undefined") {
                    x = parseInt(e.pageX, 10);
                }

                if (
                    typeof e.originalEvent !== "undefined" &&
                    typeof e.originalEvent.touches !== "undefined" &&
                    typeof e.originalEvent.touches[0] !== "undefined" &&
                    typeof e.originalEvent.touches[0].pageY !== "undefined"
                ) {
                    y = parseInt(e.originalEvent.touches[0].pageY, 10);
                } else if (
                    typeof e.originalEvent !== "undefined" &&
                    typeof e.originalEvent.pageY !== "undefined" &&
                    e.originalEvent.pageY !== null
                ) {
                    y = parseInt(e.originalEvent.pageY, 10);
                } else if (typeof e.pageY !== "undefined") {
                    y = parseInt(e.pageY, 10);
                }
            }
        } else {
            x = parseInt(p_x, 10);
            y = parseInt(p_y, 10);
        }
        var l_iStartX = parseInt(el.offset().left, 10);
        var l_iEndX = parseInt(el.outerWidth(), 10) + l_iStartX;
        var l_iStartY = parseInt(el.offset().top, 10);
        var l_iEndY = parseInt(el.outerHeight(), 10) + l_iStartY;
        return !(x < l_iStartX || x > l_iEndX || y < l_iStartY || y > l_iEndY);
    } else {
        return null;
    }
}
