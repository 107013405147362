/**
 * @file General helper functions for mobile devices.
 * @copyright 2004-2016 United Planet GmbH, Freiburg - All Rights Reserved.
 */
/* globals
   Helper:true, HelperMain, Browser,
   UpDateTimeEditControl, UpDateEditControl, UpActionControl, UpObject, upObject
   Notifier, oMessage, upFuncPart, getElement
*/

/* exported trim, Helper */

/**
 * @ignore
 * @description removes leading and trailing white spaces from a string
 */
function trim(str) {
    var a = [];
    for (var i = 0; i < str.length; i++) {
        if (str.charCodeAt(i) == 160) {
            a.push(" ");
        } else {
            a.push(str.charAt(i));
        }
    }
    var strOut = a.join("");
    return strOut.replace(/^\s*|\s*$/g, "");
}

//////////////////////////////////////////////////////////////////////////////////////
//                                HELPER-METHODS                                    //
//////////////////////////////////////////////////////////////////////////////////////
/**
 * @ignore
 */
// TODO: If Helper is refeactored, don't use constructor, use namespace.
Helper = new HelperMain();

//////////////////////////////////////////////////////////////////////////////////////
//                                STRING-OPERATIONS                                 //
//////////////////////////////////////////////////////////////////////////////////////

/**
 * @memberof Helper
 * @function sanitize
 * @param {String} value the raw input string
 * @return {String}
 * @description Converts HTML special characters to their corresponding HTML entities<br>
 * e.g. < becomes &lt;, " becomes &quot;
 */
HelperMain.prototype.sanitize = function (value) {
    var ret = value;
    if (ret) {
        ret = ret.replace(/(?:(?:&(?!amp;))|(?:%26)|(?:&#38;)|(?:&#x0026;))/gi, "&amp;");
        ret = ret.replace(/(?:(?:")|(?:%22)|(?:&#34;)|(?:&#x0022;))/gi, "&quot;");
        ret = ret.replace(/(?:(?:^)|(?:%5E)|(?:&#710;)|(?:&#x02C6;))/gi, "&circ;");
        ret = ret.replace(/(?:(?:')|(?:%27)|(?:&#39;)|(?:&#x0027;))/gi, "&apos;");
        ret = ret.replace(/(?:(?:<)|(?:%3C)|(?:&#60;)|(?:&#x003C;))/gi, "&lt;");
        ret = ret.replace(/(?:(?:>)|(?:%3E)|(?:&#62;)|(?:&#x003E;))/gi, "&gt;");
    }
    return ret;
};

//////////////////////////////////////////////////////////////////////////////////////
//                                URL-HELPER-METHODS                                //
//////////////////////////////////////////////////////////////////////////////////////

/**
 * @memberof Helper
 * @function writeLocalString
 * @param {HTMLElement} element Reference to an input control of types "date", "time", "date & time", "integer", "float", "currency" (format settings can be appended here, too).
 * @param {Number|Date} value Value to localize.
 * @param {Boolean} [fireEvent=true]
 * @return {Boolean}
 * @description Creates a local formatted string from an date object or number object and writes it as value into a html control.
 */
HelperMain.prototype.writeLocalString = function (element, value, fireEvent) {
    if (arguments.length < 2 || !element || !element.oUp) {
        return false;
    }
    if (typeof this.oWin !== "undefined") {
        this.oWin.Browser.setValue(
            element.oUp,
            element.oUp.toLocalFormat(value),
            null,
            typeof fireEvent === "boolean" ? fireEvent : true
        );
    } else {
        Browser.setValue(
            element.oUp,
            element.oUp.toLocalFormat(value),
            null,
            typeof fireEvent === "boolean" ? fireEvent : true
        );
    }
    return true;
};

/**
 * @ignore
 * @param {String} value Local formatted string.
 * @param {String} format The format string.
 * @return {Date}
 * @description Parses a local formatted date-time string.
 */
HelperMain.prototype.getDateByLocalDateTimeString = function (value, format) {
    var oInt = new UpDateTimeEditControl($('<input type="text"/>')[0], format);
    return oInt.setDateObjectFromLocal(value);
};

/**
 * @ignore
 * @param {String} value Local formatted string.
 * @param {String} format The format string.
 * @return {Date}
 * @description Parses a local formatted date string.
 */
HelperMain.prototype.getDateByLocalDateString = function (value, format) {
    var oInt = new UpDateEditControl($('<input type="text"/>')[0], format);
    return oInt.setDateObjectFromLocal(value);
};

/**
 * @memberof Helper
 * @function validateEMail
 * @param {HTMLElement} element
 * @return {Boolean} status
 * @description Validates an email address against a regular expression, returns true or false depending on the result of the validation and shows an error message, if the value in control is not a valid email adress
 */
HelperMain.prototype.validateEMail = function (element) {
    var control = element.oUp;
    var useValidation = [true, "true", 1, "1"].indexOf(control.internetFormat) > -1;

    if (useValidation === true) {
        var pattern = control.pattern;
        if (!(pattern instanceof RegExp)) {
            /**
             * @type {RegExp}
             * @description The dot-atom-text part {@link https://tools.ietf.org/html/rfc5322#section-3.2.3}
             */
            var rfc5322DotAtomText = /[a-z0-9!#$%&'*+-/=?^_`{|}~]+(\.[a-z0-9!#$%&'*+-/=?^_`{|}~]+)*/;
            /**
             * @type {RegExp}
             * @description The "@" {@link https://tools.ietf.org/html/rfc5322#section-3.4.1}
             */
            var rfc5322Separator = /@/;
            /**
             * @type {RegExp}
             * @description The domain-part without the topleveldomain.
             */
            var subDomain = /[a-z0-9-_]+(\.[a-z0-9-_]+)*/;
            /**
             * @type {RegExp}
             * @description TLDs are currently available from 2 up to 24 characters. {@link https://data.iana.org/TLD/tlds-alpha-by-domain.txt}
             */
            var tld = /\.[a-z0-9]{2,24}/;

            var domain = new RegExp(subDomain.source + tld.source);
            pattern = new RegExp(
                /^/.source + rfc5322DotAtomText.source + rfc5322Separator.source + domain.source + /$/.source,
                "i"
            );
        }

        if (element.value.length > 0) {
            if (!pattern.test(element.value)) {
                Notifier.element.error(element, oMessage.MAIL_CONSTR_PATTERN);
                $(element).attr({
                    "data-invalid": true,
                    "aria-invalid": true,
                });
                return false;
            }
        }

        $(element).removeAttr("data-invalid");
        $(element).removeAttr("aria-invalid");
        Notifier.element.hideErrorContainer(element);
        return true;
    } else {
        return true;
    }
};

/**
 * @ignore
 * @description Just overrides the format Method to ensure compatibility with old code
 */
HelperMain.prototype.format = function () {
    console.log("HELPERMAIN.FORMAT");
};

//////////////////////////////////////////////////////////////////////////////////////
//                                OBJECT-HELPER-METHODS                             //
//////////////////////////////////////////////////////////////////////////////////////
/**
 * @ignore
 * @description gets the first funcpart element on a page or the first parent funcPart, if an element is passed to the function.
 * @param {HTMLElement|jQuery} context
 * @param {Boolean} bInclusiveSelf
 */
HelperMain.prototype.getFuncPart = function (context, bInclusiveSelf) {
    var funcPart = $('[data-container="upFuncPart"]:first')[0];
    if (context) {
        if (typeof context === "string" || (typeof context === "object" && context instanceof String)) {
            if (context.match(/^[a-f0-9]*$/)) {
                funcPart = Helper.getFuncPartByGuid(context);
            }
        } else if (typeof context === "object" && context instanceof upFuncPart) {
            return context;
        } else {
            if (typeof context === "object" && context instanceof UpActionControl && !!context.oHtml) {
                context = context.oHtml;
            }
            if (bInclusiveSelf) {
                funcPart = $(context).closest('[data-container="upFuncPart"]')[0]
                    ? $(context).closest('[data-container="upFuncPart"]')[0]
                    : funcPart;
            } else {
                funcPart = $(context).parent().closest('[data-container="upFuncPart"]')[0]
                    ? $(context).parent().closest('[data-container="upFuncPart"]')[0]
                    : funcPart;
            }
        }
    }
    if (typeof funcPart !== "undefined" && typeof funcPart.oUp !== "undefined") {
        return funcPart.oUp;
    } else {
        return funcPart;
    }
};

/**
 * @ignore
 * @param {String} guid
 * @return {Object}
 * @description try to get a specific funcPart element based on its page guid
 */
HelperMain.prototype.getFuncPartByGuid = function (guid) {
    var funcPart = $(document)
        .find('[data-container="upFuncPart"][data-pageguid="' + guid + '"]')
        .get(0);
    if (!!funcPart && !!funcPart.oUp) {
        funcPart = funcPart.oUp;
    } else {
        funcPart = false;
    }
    return funcPart;
};

/**
 * @ignore
 * @description Retrieves all functional-part-containers in a page.
 */
HelperMain.prototype.getFuncPartCollection = function () {
    return $('[data-container="upFuncPart"]');
};

/**
 * @ignore
 * @param {Object} oControl element
 * @return {String} Id of fup, where control is placed.
 * @description Get the id of fup, where control is placed.
 */
HelperMain.prototype.getFupId = function (oControl) {
    var funcPart = $('[data-container="upFuncPart"]:first')[0];
    var id = "";
    if (oControl) {
        funcPart = $(oControl).closest('[data-container="upFuncPart"]')[0];
    }
    if (funcPart) {
        if (funcPart.oUp) {
            id = funcPart.oUp.id;
        } else if (funcPart.id) {
            id = funcPart.id;
        }
    }
    return id;
};

/**
 * @ignore
 * @param {Object} obj
 * @return {String} url
 * @description turns an object containing rq parameters to an url
 */
HelperMain.prototype.getUrlFromObject = function (obj) {
    if (!obj) {
        return;
    }
    var url = Helper.getBaseUrl();
    for (var i in obj) {
        if (obj.hasOwnProperty(i)) {
            url = Helper.setUrlValueByParam("rq_" + i, obj[i], url);
        }
    }
    return url;
};

/**
 * @ignore
 * @param {HTMLElement} control
 * @return {Object} obj
 * @description converts an ajaxLoader.post pseudo json to an object
 */
HelperMain.prototype.convertAjaxLoaderCallToObject = function (control) {
    var oHtml;
    var strOnclick;
    var strJSON;
    var keyValuePairs;
    var obj = null;
    var userDefParameters = {};

    if (!control) {
        return obj;
    }
    oHtml = !(control instanceof jQuery) ? $(control) : control;
    strOnclick = oHtml.attr("onclick") || null;

    if (!strOnclick) {
        console.log("no click handler found");
        return obj;
    }

    strJSON = strOnclick.match(/AjaxLoader\.post\(\{(.*?)\}\)/)[1] || null;

    if (!strJSON) {
        console.log("JSON konnte nicht aufgeloest werden");
        return obj;
    }
    if (strJSON.match(/,?'userDefParameter'\s*:\s*\[.*\]/)) {
        var strParameters = strJSON.match(/,?'userDefParameter'\s*:\s*\[.*\]/);
        var rqParameters = strParameters[0].match(/\['[^']*'\s*,\s*'[^']*'\]/);

        if (rqParameters) {
            for (var i = 0; i < rqParameters.length; i++) {
                var _k = rqParameters[i].match(/\['([^']*)'\s*,\s*'([^']*)'\]/)[1] || null;
                _k = _k.replace(/^rq_/, "");
                if (_k) {
                    var _v = rqParameters[i].match(/\['([^']*)'\s*,\s*'([^']*)'\]/)[2] || "";
                    userDefParameters[_k] = _v;
                }
            }
        }
        strJSON = strJSON.replace(/,?'userDefParameter'\s*:\s*\[.*\]/, "");
    }
    keyValuePairs = strJSON.split(/,/) || null;
    if (keyValuePairs) {
        obj = {};
        for (var j = 0; j < keyValuePairs.length; j++) {
            var _key = keyValuePairs[j].split(/:/)[0] || null;
            var _value = keyValuePairs[j].split(/:/)[1] || "";
            if (_key) {
                _key = _key.trim();
                _key = _key.replace(/^'/g, "");
                _key = _key.replace(/'$/g, "");
                _value = _value.replace(/^'/g, "");
                _value = _value.replace(/'$/g, "");

                obj[_key] = _value;
            }
        }
        for (var k in userDefParameters) {
            if (userDefParameters.hasOwnProperty(k)) {
                obj[k] = userDefParameters[k];
            }
        }
        return obj;
    }
};

/**
 * @memberof Helper
 * @function getUpObject
 * @param {Object} element
 * @return {upObject}
 * @description Returns the upObject of an element. Element can be selected either by id, guid, by sizzle selector or passed directly as html element.
 */
HelperMain.prototype.getUpObject = function (element) {
    var oUp = null;
    var control = null;
    var _col = null;
    if (element) {
        if (typeof element === "string" || element instanceof String) {
            element = jQuery.trim(element.toString());
            // string looks like an id
            if (element.match(/^(?:#?)(?:ID_)/)) {
                control = $((element.indexOf("#") === 0 ? "" : "#") + element);
                oUp = control.prop("oUp");
            } else if (element.match(/^[a-fA-F0-9]*$/)) {
                // string looks like an element guid
                control = getElement(element);
                if (control) {
                    oUp = control.oUp;
                }
            } else {
                // other string based selector -> use sizzle enginge to gather elements
                control = $(element);
                _col = [];
                if (control.length) {
                    $(element).each(function () {
                        if (this.oUp) {
                            _col.push(this.oUp);
                        }
                    });

                    oUp = _col.length > 1 ? _col : _col[0];
                }
            }
        } else if (typeof element === "object" && element instanceof Array) {
            // collection of elements (for example as a result of a jquery selector)
            _col = [];
            control = $(element);

            if (control.length) {
                $(element).each(function () {
                    if (this.oUp) {
                        _col.push(this.oUp);
                    }
                });
                oUp = _col.length > 1 ? _col : _col[0];
            }
        }
        // element passed as upobject -> may be in a loop or in cases we don't know what we get
        if (typeof element === "object" && (element instanceof upObject || element instanceof UpObject)) {
            oUp = element;
        } else if (element instanceof HTMLElement && typeof element.oUp !== "undefined") {
            // element is passed as plain HTML node
            oUp = element.oUp;
        } else if (element instanceof jQuery) {
            // element is passed as jquery object
            oUp = element.prop("oUp");
        }
    }
    return oUp;
};

/**
 * @memberof Helper
 * @function getGuid
 * @return {String} guid
 * @description Generates and returns a new GUID.
 */
HelperMain.prototype.getGuid = function () {
    var guid = "";
    for (var x = 0; x < 40; x++) {
        guid += "0123456789ABCDEF".charAt(Math.round(Math.random() * 1000) % 16);
    }
    return guid;
};
/**
 * @class
 * @private
 */
function upEventHelper(helper) {
    /**
     * @private
     */
    this.oHlp = helper;
    /**
     * @private
     */
    this.oWin = this.oHlp.oWin;
    /**
     * @private
     */
    this.oDoc = this.oHlp.oDoc;
}
/**
 * @private
 */
upEventHelper.prototype.cancelBubble = function (e) {
    try {
        if (e.cancelable) e.preventDefault();
        if (e.stopPropagation) e.stopPropagation();

        e.cancelBubble = true;

        if (e.preventDefault) {
            e.preventDefault();
        } else {
            e.returnValue = false;
        }
    } catch (e) {
        // IE HACK (Stichwort Event Clone)
        if (Browser.ie && window.event) {
            try {
                if (window.event.cancelable) window.event.preventDefault();
                if (window.event.stopPropagation) window.event.stopPropagation();
                window.event.cancelBubble = true;
                if (window.event.preventDefault) {
                    window.event.preventDefault();
                } else {
                    window.event.returnValue = false;
                }
            } catch (e) {
                console.error(e);
            }
        }
        //window.event
    }
};
HelperMain.prototype.event = new upEventHelper(this);
