/**
 * @file Mobile ajax classes.
 * @copyright 2004-2016 United Planet GmbH, Freiburg - All Rights Reserved.
 */
/* globals $: true, Notifier, ix, log */

///////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////                                   IxMobile                                      /////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////
/*
 *   @private
 *   @ignore
 */
if (typeof $ === "undefined") {
    $ = {};
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////                                   EVAL_IN_GLOBAL_CONTEXT                        /////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**
    @function
    @private
    @name evalInGlobalContext
    @param strCode Javascript code as string.
    @param isFile <code>True</code>, if the code is the content of a file, which was loaded by ajax.
    @param id id if appended script block, if isFile = true.
    @description
    Evaluates javascript code in global context. Variables are declared inside the window namespace and not only in the eval namespace.
*/
window.evalInGlobalContext = function (strCode, isFile, id) {
    var strId = "Container_PageScript";
    if (arguments.length === 3 && !!id) {
        strId = id;
    }
    if (
        (typeof strCode === "string" || (typeof strCode === "object" && strCode instanceof String)) &&
        strCode.length < 1
    ) {
        return;
    } else if (typeof strCode === "object" && strCode instanceof HTMLElement && strCode.innerHTML.length < 1) {
        return;
    } else {
        var evalCode = "";
        if (typeof strCode === "string" || (typeof strCode === "object" && strCode instanceof String)) {
            evalCode = strCode.toString();
        } else if (typeof strCode === "object" && (strCode instanceof HTMLElement || strCode instanceof jQuery)) {
            if (strCode instanceof HTMLElement) {
                evalCode = strCode.innerHTML;
            } else if (strCode instanceof jQuery) {
                evalCode = strCode.get(0).innerHTML;
            }
        }
        if ($("#" + strId).length) {
            $("#" + strId).remove();
        }
        var scriptContainer = $(
            '<script type="text/javascript" defer="false" id="' + strId + '">' + evalCode + "</script>"
        );
        try {
            if (isFile && evalCode.match(/^\s*</) && evalCode.match(/>\s*$/)) {
                if (!$(evalCode).length) {
                    var error = new Error();
                    error.message = "evalInGlobalContext : An Error has occured while parsing";
                    throw error;
                } else {
                    $(evalCode).appendTo($("head"));
                }
            } else {
                $(document.body).append(scriptContainer);
            }
        } catch (ex) {
            Notifier.status.error(ex.message + "<br>See console for further informations.", "Javascript - Error");
            window.oError = {
                message: ex.message,
                stack: ex.stack,
                strCode: "" + evalCode,
            };
            if (typeof window.console !== "undefined") {
                console.log(ex.message);
                console.log(ex.stack);
                console.log(window.oError);
            } else {
                log(ex.message);
                log(ex.stack);
                log(window.oError);
            }
        }
    }
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//                                                ajaxLoader for mobile devices                                       //
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
var mAjaxLoader = function (options) {
    //private:
    function ajaxLoader(options) {
        jQuery.extend(this, {}, options);
    }

    //public:
    ajaxLoader.prototype.post = function (actionControl) {
        var settings = {
            method: "POST",
        };
        settings = $.extend(true, {}, settings, ix.loader.getSettingsFromActionControl(actionControl));
        return ix.loader.process(settings);
    };
    ajaxLoader.prototype.get = function (actionControl) {
        var settings = {
            method: "GET",
        };
        settings = $.extend(true, {}, settings, ix.loader.getSettingsFromActionControl(actionControl));
        return ix.loader.process(settings);
    };
    ajaxLoader.prototype.loadUrl = function (url, navigationType, oHtml) {
        var settings = {
            method: "GET",
            url: url,
            context: oHtml,
        };
        return ix.loader.process(settings);
    };
    ajaxLoader.prototype.hijax = function (oControl) {
        var settings = {
            method: "GET",
        };
        settings = $.extend(true, {}, settings, ix.loader.getSettingsFromActionControl(oControl));
        return ix.loader.process(settings);
    };
    ajaxLoader.prototype.loadLastPage = function () {
        return ix.loader.history.loadLastPage();
    };
    ajaxLoader.prototype.loadFromHistory = function (oHistoryEntry) {
        return ix.loader.history.loadLastPage(oHistoryEntry, true);
    };
    return new ajaxLoader(options); // jscs:ignore requireCapitalizedConstructors
};
window.ajaxLoader = new mAjaxLoader(); // jscs:ignore requireCapitalizedConstructors

//////////////////////////////////////////////////////////////////////////////////////////////////////
//                          AJAX-LOADER-SPECIFIC - HELPER-METHODS                                   //
//////////////////////////////////////////////////////////////////////////////////////////////////////
/**
    @private
    @ignore
    @description
    removes old error containers on page, if found
*/
$(window).on("stageChange", function () {
    $('[data-role="numErrorPanel"][data-type!="static"]').remove();
});
