/**
 * @file upTooltipContainer
 * @copyright 2004-2016 United Planet GmbH, Freiburg - All Rights Reserved.
 */
/* globals upContainer, reloadTooltip, tooltipManager, Helper, debug */
/**
    @class upTooltipContainer
    @private
    @param {Object} options
    @description
    general tooltip control (upObject for tooltip containers)
*/
function upTooltipContainer(options) {
    this.upType = "upTooltipContainer";

    this.defaults = {
        oHtml: null,
        oSource: null,
        oTarget: null,
        tooltipSettings: null,
        oAjaxSettings: {
            type: "GET",
            dataType: "xml",
        },
        oContext: {
            oTarget: {},
            oSource: {},
            oAjaxSettings: {},
        },
    };
    this.options = $.extend({}, this.defaults, options); // extend this.options with this.defaults and options, if present
    this.oHtml = this.options.oHtml; // bind oHtml to actionControl
    this.oFup = this.oHtml ? $(this.oHtml).getFuncPart() : Helper.getFuncPart();
    this.tooltipSettings = this.options.tooltipSettings; // Target Information for AjaxLoader
    this.oContext = this.options.oContext; // Source Information fpr AjaxLoader
    this.oTarget = this.options.oContext.oTarget;
    this.oSource = this.options.oContext.oSource;
    this.oAjaxSettings = this.options.oContext.oAjaxSettings;
    if (this.options.tooltip) {
        this.tooltip = this.options.tooltip;
    }
    return this;
}
/**
    @private
    @constructs upActionControl
    @description
    upActionControl is subclass of upControl
*/
upTooltipContainer.prototype = new upContainer();
/**
    @methodOf upTooltipContainer
    @private
    @description
    reload method for the tooltip dialog
*/
upTooltipContainer.prototype.reload = function () {
    reloadTooltip(this);
};
upTooltipContainer.prototype.cleanUp = function () {
    if (this.tooltip && this.tooltip.cleanUp) {
        this.tooltip.cleanUp();
    }
};
/**
    @methodOf upTooltipContainer
    @private
    @description
    closes the tooltip via the mobile tooltip manager
*/
upTooltipContainer.prototype.close = function () {
    tooltipManager.removeTooltip(this);
};
upTooltipContainer.prototype.remove = function () {
    if (!!this.tooltip && !!this.tooltip.remove) {
        this.tooltip.remove();
    } else {
        if (this.tooltipSettings.onBeforeClose) {
            try {
                this.oHtml.oUp.triggerEvent("onBeforeClose");
            } catch (ex) {
                debug("Error while excecuting callback onBeforeClose");
            }
        }
        try {
            this.cleanUp();
        } catch (ex) {
            debug("Error while executing cleanUp Method");
        }
        if (this.tooltipSettings.oncleanup) {
            try {
                this.oHtml.oUp.triggerEvent("oncleanup");
            } catch (ex) {
                debug("Error while excecuting callback oncleanup");
            }
        }
        this.oHtml.remove();
        if (this.tooltipSettings.onclose) {
            try {
                this.oHtml.oUp.triggerEvent("onclose");
            } catch (ex) {
                debug("Error while excecuting callback onclose");
            }
        }
    }
};
/**
    @methodOf upTooltipContainer
    @private
    @description
    closes the tooltip via the mobile tooltip manager
*/
upTooltipContainer.prototype.closeTooltipPopUp = function () {
    this.close();
};
/**
    @methodOf upTooltipContainer
    @private
    @description
    closes the tooltip via the mobile tooltip manager
*/
upTooltipContainer.prototype.closePopup = function () {
    this.close();
};
/**
    @methodOf upTooltipContainer
    @private
    @description
    reload method for the tooltip dialog
*/
upTooltipContainer.prototype.reload = function () {
    var ret = null;
    if (this.tooltip && typeof this.tooltip.getId !== "undefined") {
        ret = this.tooltip.getId();
    } else if (this.options.tooltip && typeof this.options.tooltip.getId !== "undefined") {
        ret = this.options.tooltip.getId();
    }
    return ret;
};
