/* globals Browser, UpTimeEditControl, UpDateEditControl, UpDateTimeEditControl, upObject, getElement, Helper, log, debug, InvalidArgumentException, DEBUG, ix: true */
/**
    @namespace Browser
*/
/**
    @memberOf Browser
    @public
*/
Browser.mobile = true;

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
    @public
    @function
    @memberOf Browser
    @param {Object} html input control.
    @param {string} value to write.
    @returns {Boolean} <code>True</code> or <code>false</code>.
    @description
    Writes value of a control.
*/
Browser.setValue = function (oObj, value, attr, isTriggerOnChange) {
    var ret = false;
    var oHtml;
    var oUp;

    try {
        if (arguments.length < 1 || typeof oObj === "undefined") {
            throw new InvalidArgumentException("Element must not be undefined");
        }

        //////////////////////////////////////////////////////////////////////////////////////////////////
        ///////                                 get HTML-Element and upObject                           //
        //////////////////////////////////////////////////////////////////////////////////////////////////
        if (typeof oObj === "string" || (typeof oObj === "object" && oObj instanceof String)) {
            var selector = oObj.toString();
            if (selector.match(/^[a-fA-F0-9]+$/) && !!getElement(selector)) {
                // guid?
                oObj = getElement(selector);
            } else {
                try {
                    if (selector.match(/^(?:#)?ID_/)) {
                        oObj = $((selector.indexOf("#") !== 0 ? "#" : "") + selector).get(0);
                    } else {
                        oObj = $("" + selector).get(0);
                    }
                } catch (ex) {
                    if (typeof window.console !== "undefined") {
                        console.log("Element Not Found Exception");
                    } else {
                        log("Element not found Exception");
                    }
                    return false;
                }
            }
            oHtml = oObj;
            if (typeof oHtml.oUp !== "undefined") {
                oUp = oHtml.oUp;
            }
        } else if (typeof oObj === "object" && (oObj instanceof HTMLElement || oObj instanceof jQuery)) {
            if (oObj instanceof jQuery) {
                oHtml = oObj.get(0);
            } else {
                oHtml = oObj;
            }
            if (typeof oHtml.oUp !== "undefined" && !!oHtml.oUp) {
                oUp = oHtml.oUp;
            }
        } else if (typeof oObj === "object" && oObj instanceof window.UpObject) {
            oUp = oObj;
            if (typeof oUp.oHtml !== "undefined" && !!oUp.oHtml) {
                oHtml = oUp.oHtml;
            }
        }
        //////////////////////////////////////////////////////////////////////////////////////////////////
        if ($.isFunction(oUp.setValue)) {
            ret = this._setValueUpObject(oUp, value, attr, isTriggerOnChange);

            // return this._setValueUpObject(oUp, value, attr, isTriggerOnChange);
        } else {
            if (arguments.length < 4 || typeof isTriggerOnChange != "boolean") {
                isTriggerOnChange = true;
            }
            if (typeof oObj === "object" && (oObj instanceof window.UpObject || oObj instanceof upObject)) {
                ret = this.setValueByUpObject(oObj, value, attr, isTriggerOnChange);
            } else if (typeof oObj === "object" && !oObj.id) {
                ret = this.setRawValue(oObj, value, attr, isTriggerOnChange);
            } else {
                ret = this.setValueByElementId(oObj.id, value, attr, isTriggerOnChange);
            }
        }
    } catch (ex) {
        if (typeof DEBUG !== "undefined" && !!DEBUG) {
            console.log(ex);
        }
    }
    if (!$.isDeferred(ret)) {
        if (ret) {
            ret = $.Deferred().resolve();
        } else {
            ret = $.Deferred().reject();
        }
    }
    return ret;
};

/**
    @public
    @function
    @memberOf Browser
    @param {String} strId Id of html element.
    @param {String} value Value to write.
    @return {Boolean} <code>True</code> or <code>false</code>.
    @description
    Writes value of a control.
*/
Browser.setValueByElementId = function (strId, value, attr, isTriggerOnChange) {
    if (arguments.length < 4 || typeof isTriggerOnChange != "boolean") {
        isTriggerOnChange = true;
    }
    if (!strId) {
        return false;
    }
    if (!value) {
        value = "";
    }
    var oHtml = document.getElementById(strId);
    if (!oHtml) {
        return false;
    }
    var ret = false;
    var tag = oHtml.tagName.toUpperCase();
    // Auswahlliste
    if (tag == "SELECT" && oHtml.options) {
        for (var i = 0; i < oHtml.options.length; i++) {
            if (oHtml.options[i].value === value) {
                ret = true;
                oHtml.options[i].selected = true;
            } else {
                oHtml.options[i].selected = false;
            }
        }
        if (!ret) {
            for (var j = 0; j < oHtml.options.length; j++) {
                if (oHtml.options[j].text === value) {
                    ret = true;
                    oHtml.options[j].selected = true;
                } else {
                    oHtml.options[j].selected = false;
                }
            }
        }
    } else if (
        tag == "TEXTAREA" ||
        (tag == "INPUT" &&
            (oHtml.type == "text" ||
                oHtml.type == "hidden" ||
                oHtml.type == "number" ||
                oHtml.type == "email" ||
                oHtml.type == "url" ||
                oHtml.type == "tel" ||
                oHtml.type == "search"))
    ) {
        // Input-Kontrolle vom Typ Text | Textarea | Hidden
        if (
            typeof oHtml.oUp !== "undefined" &&
            oHtml.oUp instanceof (UpTimeEditControl || UpDateEditControl || UpDateTimeEditControl) &&
            typeof value == "object" &&
            typeof value.getTime == "function"
        ) {
            oHtml.value = oHtml.oUp.toLocalFormat(value);
        } else {
            oHtml.value = value;
            ret = true;
        }
    } else if (tag == "INPUT" && (oHtml.type == "checkbox" || oHtml.type == "radio")) {
        // Checkbox
        oHtml.checked = (typeof value == "boolean" && value) || value == "1" || value == "true";
        ret = true;
    } else if (!!oHtml.getAttribute("data-type") && oHtml.getAttribute("data-type") === "distributioncontrol") {
        // Mobile Distribution Control
        var _arr;
        if (value instanceof Array) {
            _arr = value;
        } else if (typeof value === "string") {
            _arr = value.split("||");
        }
        var id = oHtml.id;
        var txt = document.getElementById(id + "_selected_hidden");
        var _str = '{"type":"selected", "selected": [';

        for (var k = 0; k < _arr.length; k++) {
            if (k > 0) {
                _str = _str + ",";
            }
            _str = _str + '"' + _arr[k] + '"';
        }
        _str = _str + "]}";
        txt.value = _str;
        $(txt).trigger("change");
        $("#" + id + "_wrapper").trigger("externalchange");
        ret = true;
    } else if (!!oHtml.getAttribute("data-type") && oHtml.getAttribute("data-type") === "uplistboxcontrol") {
        // mobile listbox control
        if (!value.length) {
            return;
        }
        var ul = oHtml.getElementsByTagName("ul")[0];
        var arrValues = value.split("||");
        var name = oHtml.id.match(/ID_([a-zA-Z0-9_-]*)/)[1];

        // mehrfachauswahl
        /* jshint -W004 */
        if (!!oHtml.getAttribute("data-multiple") && oHtml.getAttribute("data-multiple") == "1") {
            for (var l = 0; l < ul.children.length; l++) {
                ul.children[l].setAttribute("data-selected", "");
            }
            for (var m = 0; m < ul.children.length; m++) {
                for (var n = 0; n < arrValues.length; n++) {
                    if (
                        ul.children[m].getAttribute("data-recid") &&
                        ul.children[m].getAttribute("data-recid") == arrValues[n]
                    ) {
                        ul.children[m].setAttribute("data-selected", "selected");
                    }
                }
            }
        } else {
            //einfache auswahl
            for (var o = 0; o < ul.children.length; o++) {
                for (var p = 0; p < arrValues.length; p++) {
                    if (
                        ul.children[o].getAttribute("data-recid") &&
                        ul.children[o].getAttribute("data-recid") == arrValues[p]
                    ) {
                        ul.children[o].setAttribute("data-selected", "selected");
                    } else {
                        ul.children[o].setAttribute("data-selected", "");
                    }
                }
            }
        }
        /* jshint +W004 */

        var hiddenTextField = $('input:hidden[name="' + name + '"]');
        //var hiddenTextField = $(oHtml).next('input:hidden')
        hiddenTextField.val(value);
        ret = true;
    } else if (!!oHtml.getAttribute("data-type") && oHtml.getAttribute("data-type") === "uptreecontrol") {
        if (typeof oHtml.oUp == "object") {
            ret = oHtml.oUp.showFolder(value);
        } else {
            $(oHtml).data("default", value);
        }
    } else {
        // HtmlValue browserspezifisch schreiben
        if (typeof oHtml.innerHTML == "string") {
            oHtml.innerHTML = value;
            ret = true;
        } else if (oHtml.firstChild && oHtml.firstChild.nodeValue) {
            oHtml.firstChild.nodeValue = value;
            ret = true;
        } else {
            ret = false;
        }
    }
    if (!!ret && isTriggerOnChange === true) {
        $(oHtml).trigger("change");
    }
    return ret;
};
/**
    @function
    @memberOf Browser
    @private
    @param {upObject} oUp
    @param {String} value
    @param {String} attr
    @param {Boolean} isTriggerOnChange
    @description
    sets the value of an element via the upObject that is associated with the element.
*/
Browser.setValueByUpObject = function (oUp, value, attr, isTriggerOnChange) {
    if (typeof oUp !== "undefined" && !!oUp) {
        switch (oUp.upType) {
            // filtercalendar control
            case "upFilterCtrlCalendar":
                if (attr) {
                    var val;
                    if (typeof value === "string" || (typeof value === "object" && value instanceof String)) {
                        val = val.toString();
                        val = Helper.getDateByIsoString(val);
                    } else if (typeof value === "object" && value instanceof Date) {
                        val = value;
                    }
                    if (val) {
                        if (attr.toLowerCase() == "from") {
                            oUp.dtFrom = val;
                        } else if (attr.toLowerCase() == "to") {
                            oUp.dtTo = val;
                        }
                    }
                }
                break;
            default:
                this.setValueByElementId(oUp.oHtml.id, value, attr, isTriggerOnChange);
                break;
        }
    }
};
/**
    @private
    @function
    @memberOf Browser
    @param {Object} oUp reference Html-Control.
    @param {String} value reference value.
    @param {Array} attr Attribute
    @param {Boolean} isTriggerOnChange Flag indicating if onchange should be triggered after setting the new value
    @returns {Boolean} ret
    @description
    fills a given input control with the value that is passed to the function. Triggers onchange Events, if isTriggerOnChange is set to true
*/
Browser.setRawValue = function (oUp, value) {
    var ret = false;
    if (!oUp) {
        return ret;
    } else {
        if (oUp.hasAttribute("value") || oUp instanceof HTMLInputElement) {
            oUp.value = value.toString();
        } else {
            oUp.innerHTML = value.toString();
        }
        ret = true;
        return ret;
    }
};
/**
    @public
    @function
    @memberOf Browser
    @param {Object} oControl reference Html-Control.
    @param {Array} attr Attribute
    @returns {String} ret
    @description
    Reads  value of a control.
*/
Browser.getValueByUpObject = function (oObj, attr, settings) {
    var oUp;
    var options = {
        type: "String",
    };
    if (typeof settings !== "undefined") {
        options = $.extend(options, {}, settings);
    }
    if (typeof attr !== "undefined" && !!attr) {
        options.attr = attr;
    }
    var ret = false;
    if (typeof oObj === "undefined") {
        throw new InvalidArgumentException("Element must not be undefined");
    }
    // try to get element from string (selector, id or guid)
    if (typeof oObj === "string" || oObj instanceof String) {
        var strObj = oObj.toString();
        if (strObj.match(/^[a-fA-F0-9]+$/)) {
            // guid?
            oObj = getElement(strObj);
        }
        if (!strObj.match(/^[a-fA-F0-9]+$/) || !oObj) {
            try {
                if (strObj.match(/^(?:#)?ID_/)) {
                    oObj = $((strObj.indexOf("#") !== 0 ? "#" : "") + strObj).get(0);
                } else {
                    oObj = $("" + strObj).get(0);
                }
            } catch (ex) {
                if (typeof window.console !== "undefined") {
                    console.log("Element Not Found Exception");
                } else {
                    log("Element not found Exception");
                }
                return false;
            }
        }
    }
    if (typeof oObj === "object" && (oObj instanceof window.UpObject || oObj instanceof upObject)) {
        oUp = oObj;
    } else if (typeof oObj === "object" && oObj instanceof jQuery && typeof oObj[0].oUp !== "undefined") {
        oUp = oObj[0].oUp;
    } else if (typeof oObj === "object" && oObj instanceof HTMLElement && typeof oObj.oUp !== "undefined") {
        oUp = oObj.oUp;
    }
    if (oUp) {
        ret = oUp.getValue(options);
        switch (oUp.getUpType()) {
            case "upRadioControlGroup":
            case "upCheckControlGroup":
                ret = typeof ret === "undefined" || !ret ? false : ret;
                break;
        }
    } else {
        throw new InvalidArgumentException("Cannot retrieve informations on the current Element");
    }
    return ret;
};
/**
    @function
    @memberof Browser
    @param {Object} oObj
    @param {String} attr
    @description
    get the value of a control
*/
Browser.getValue = function (oObj, attr) {
    var oHtml; // html-element
    var ret = false; // return status as boolean
    var oUp;
    if (typeof oObj === "string" || (typeof oObj === "object" && oObj instanceof String)) {
        var selector = oObj.toString();
        if (selector.match(/^[a-fA-F0-9]+$/) && !!getElement(selector)) {
            // guid?
            oObj = getElement(selector);
        } else {
            try {
                if (selector.match(/^(?:#)?ID_/)) {
                    oObj = $((selector.indexOf("#") !== 0 ? "#" : "") + selector).get(0);
                } else {
                    oObj = $("" + selector).get(0);
                }
            } catch (ex) {
                if (typeof window.console !== "undefined") {
                    console.log("Element Not Found Exception");
                } else {
                    log("Element not found Exception");
                }
                return false;
            }
        }
        oHtml = oObj;
        if (typeof oHtml.oUp !== "undefined") {
            oUp = oHtml.oUp;
        }
    } else if (typeof oObj === "object" && (oObj instanceof HTMLElement || oObj instanceof jQuery)) {
        if (oObj instanceof jQuery) {
            oHtml = oObj.get(0);
        } else {
            oHtml = oObj;
        }
        if (typeof oHtml.oUp !== "undefined" && !!oHtml.oUp) {
            oUp = oHtml.oUp;
        }
    } else if (typeof oObj === "object" && (oObj instanceof window.UpObject || oObj instanceof upObject)) {
        oUp = oObj;
        if (typeof oUp.oHtml !== "undefined" && !!oUp.oHtml) {
            oHtml = oUp.oHtml;
        }
    }
    if (typeof oUp !== "undefined" && typeof oUp.getValue !== "undefined" && oUp.upType !== "upDataControl") {
        try {
            ret = this._getValueUpObject(oUp, attr);
        } catch (ex) {
            ret = this._getValueByHtml(oHtml, attr);
        }
    } else {
        ret = this._getValueByHtml(oHtml, attr);
    }
    return ret;
};
Browser._getValueByHtml = function (oObj, attr) {
    var oHtml; // html-element
    var tag; // the tagName as string
    var ret = false; // return status as boolean
    var items; // list items, if dealing withs list elements
    var ul; // unordered lists and extendedlistbox elements
    var control; // the elements upObject
    var jqSelectedOptions;

    if (typeof oObj === "string" || oObj instanceof String) {
        var strObj = oObj.toString();
        if (strObj.match(/^[a-fA-F0-9]+$/)) {
            // guid?
            oObj = getElement(strObj);
        }
        if (!strObj.match(/^[a-fA-F0-9]+$/) || !oObj) {
            try {
                if (strObj.match(/^(?:#)?ID_/)) {
                    oObj = $((strObj.indexOf("#") !== 0 ? "#" : "") + strObj).get(0);
                } else {
                    oObj = $("" + strObj).get(0);
                }
            } catch (ex) {
                if (typeof window.console !== "undefined") {
                    console.log("Element Not Found Exception");
                } else {
                    log("Element not found Exception");
                }
                return false;
            }
        }
    }
    // always based on Html-Control, not on Up-Control
    oHtml = oObj;
    if (oObj.oHtml) {
        oHtml = oObj.oHtml;
    }
    tag = oHtml.tagName.toUpperCase();

    // Auswahlliste
    if (tag == "SELECT" && oHtml.options) {
        items = [];
        control = oHtml.oUp;
        for (var j = 0; j < oHtml.options.length; j++) {
            if (oHtml.options[j].selected) {
                // Spezifischen Wert aus einem zusammengesetzten PK ermitteln
                if (
                    typeof attr == "object" &&
                    attr !== null &&
                    typeof attr.attr != "undefined" &&
                    attr.attr == "key" &&
                    typeof attr.partKey != "undefined"
                ) {
                    jqSelectedOptions = $("option[data-key-map]:selected", oHtml);
                    if (
                        jqSelectedOptions.length > 0 &&
                        typeof $(jqSelectedOptions.get(0)).data("key-map")[attr.partKey] != "undefined"
                    ) {
                        items.push($(jqSelectedOptions.get(0)).data("key-map")[attr.partKey]);
                    } else if (jqSelectedOptions.length > 0) {
                        debug(
                            "Browser.getValue could not find data-key-map entry. Infos: name=" +
                                control.userName +
                                " | type=" +
                                control.upType +
                                " | map:" +
                                jqSelectedOptions.get(0).getAttribute("data-key-map") +
                                " | mapKey:" +
                                attr.partKey,
                            "error"
                        );
                    }
                } else {
                    if (typeof attr !== "undefined" && attr && attr == "key") {
                        if (oHtml.options[j].hasAttribute && oHtml.options[j].hasAttribute("data-recid")) {
                            items.push(oHtml.options[j].getAttribute("data-recid"));
                        } else {
                            items.push(oHtml.options[j].value);
                        }
                    } else if (typeof oHtml.options[j].value == "string" && oHtml.options[j].value.length > 0) {
                        items.push(oHtml.options[j].value);
                    } else {
                        items.push(oHtml.options[j].text);
                    }
                    if (!oHtml.multiple) {
                        break;
                    }
                }
            }
        }
        if (items.length === 1) {
            ret = items[0];
        } else if (items.length > 1) {
            ret = "" + Helper.doCharStuffing(items);
            ret.bStuffed = true;
        }
    } else if (
        tag == "TEXTAREA" ||
        (tag == "INPUT" &&
            (oHtml.type == "text" ||
                oHtml.type == "hidden" ||
                oHtml.type == "number" ||
                oHtml.type == "email" ||
                oHtml.type == "url" ||
                oHtml.type == "tel" ||
                oHtml.type == "search"))
    ) {
        // Input-Kontrolle vom Typ Text | Textarea | Hidden
        ret = oHtml.value;
    } else if (tag == "DIV" && !!$(oHtml).data("control") && $(oHtml).data("control").indexOf("filevcontrol") > -1) {
        // Fileansichten
        $(oHtml)
            .find("[data-filename]")
            .each(function () {
                if (!ret) {
                    ret = "";
                }
                if (ret !== "") {
                    ret += "||";
                }
                ret += $(this).data("filename");
            });
    } else if (tag == "DIV" && !!$(oHtml).data("control") && $(oHtml).data("control").indexOf("filecontrol") > -1) {
        ret = "";
        $(oHtml)
            .find("[data-filename]")
            .each(function () {
                if (ret !== "") {
                    ret += "||";
                }
                ret += $(this).data("filename");
            });
        if ($(oHtml).find(".fileupload-buttonbar").length === 0) {
            var oInput = $(oHtml).find("[type=file]").get(0);
            for (var i = 0; i < oInput.files.length; i++) {
                if (ret !== "") {
                    ret += "||";
                }
                ret += oInput.files[i].name;
            }
        }
    } else if (tag == "INPUT" && (oHtml.type == "checkbox" || oHtml.type == "radio")) {
        // Checkbox
        ret = oHtml.checked;
    } else if (!!oHtml.getAttribute("data-type") && oHtml.getAttribute("data-type") === "distributioncontrol") {
        // Mobile Distribution Control
        var id = oHtml.id;
        var txt = document.getElementById(id + "_selected_hidden");
        var obj = $.parseJSON($(txt).val());

        if (obj !== null) {
            ret = Helper.doCharStuffing(obj.selected);
        }
    } else if (!!oHtml.getAttribute("data-type") && oHtml.getAttribute("data-type") === "uplistboxcontrol") {
        // mobile listboxcontrol
        items = [];
        ul = oHtml.getElementsByTagName("ul")[0];
        control = oHtml.oUp;
        /* jshint -W004 */
        for (var k = 0; k < ul.children.length; k++) {
            if (
                !!ul.children[k].getAttribute("data-selected") &&
                ul.children[k].getAttribute("data-selected").toLowerCase() === "selected"
            ) {
                // Spezifischen Wert aus einem zusammengesetzten PK ermitteln
                if (
                    typeof attr == "object" &&
                    attr !== null &&
                    typeof attr.attr !== "undefined" &&
                    attr.attr == "key" &&
                    typeof attr.partKey !== "undefined"
                ) {
                    jqSelectedOptions = $("li[data-key-map]:selected", oHtml);
                    if (
                        jqSelectedOptions.length > 0 &&
                        typeof $(jqSelectedOptions.get(0)).data("key-map")[attr.partKey] != "undefined"
                    ) {
                        items.push($(jqSelectedOptions.get(0)).data("key-map")[attr.partKey]);
                    } else if (jqSelectedOptions.length > 0) {
                        debug(
                            "Browser.getValue could not find data-key-map entry. Infos: name=" +
                                control.userName +
                                " | type=" +
                                control.upType +
                                " | map:" +
                                jqSelectedOptions.get(0).getAttribute("data-key-map") +
                                " | mapKey:" +
                                attr.partKey,
                            "error"
                        );
                    }
                }

                if (typeof attr === "undefined") {
                    items.push(ul.children[k].getAttribute("data-recid"));
                } else if (typeof attr !== "undefined" && !!attr && attr === "key") {
                    if (ul.children[k].hasAttribute && ul.children[k].hasAttribute("data-recid")) {
                        items.push(ul.children[k].getAttribute("data-recid"));
                    } else {
                        items.push(ul.children[k].innerHTML);
                    }
                } else {
                    items.push(ul.children[k].innerHTML);
                }
            }
        }
        /* jshint +W004 */
        if (items.length == 1) {
            ret = items[0];
        } else if (items.length > 1) {
            /* jshint -W053 */
            ret = "" + new String(Helper.doCharStuffing(items));
            /* jshint +W053 */
            ret.bStuffed = true;
        }
    } else if (!!oObj.upType && oObj.upType === "upListboxControl") {
        // extended listboxcontrol
        items = [];
        ul = oHtml;
        control = oHtml.oUp;
        /* jshint -W004 */
        for (var l = 0; l < ul.children.length; l++) {
            if (
                !!ul.children[l].getAttribute("data-selected") &&
                ul.children[l].getAttribute("data-selected").toLowerCase() === "selected"
            ) {
                // Spezifischen Wert aus einem zusammengesetzten PK ermitteln
                if (
                    typeof attr == "object" &&
                    attr !== null &&
                    typeof attr.attr != "undefined" &&
                    attr.attr == "key" &&
                    typeof attr.partKey != "undefined"
                ) {
                    jqSelectedOptions = $("li[data-key-map]:selected", oHtml);
                    if (
                        jqSelectedOptions.length > 0 &&
                        typeof $(jqSelectedOptions.get(0)).data("key-map")[attr.partKey] != "undefined"
                    ) {
                        items.push($(jqSelectedOptions.get(0)).data("key-map")[attr.partKey]);
                    } else if (jqSelectedOptions.length > 0) {
                        debug(
                            "Browser.getValue could not find data-key-map entry. Infos: name=" +
                                control.userName +
                                " | type=" +
                                control.upType +
                                " | map:" +
                                jqSelectedOptions.get(0).getAttribute("data-key-map") +
                                " | mapKey:" +
                                attr.partKey,
                            "error"
                        );
                    }
                }

                if (typeof attr !== "undefined" && !!attr && attr === "key") {
                    if (ul.children[l].hasAttribute && ul.children[l].hasAttribute("data-recid")) {
                        items.push(ul.children[l].getAttribute("data-recid"));
                    } else {
                        items.push(ul.children[l].innerHTML);
                    }
                } else {
                    items.push(ul.children[l].innerHTML);
                }
            }
        }
        /* jshint +W004 */
        if (items.length == 1) {
            ret = items[0];
        } else if (items.length > 1) {
            /* jshint -W053 */
            ret = new String(Helper.doCharStuffing(items));
            /* jshint +W053 */
            ret.bStuffed = true;
        }
    } else if (!!oHtml.getAttribute("data-type") && oHtml.getAttribute("data-type") === "uptreecontrol") {
        return oHtml.oUp.getSelectedItem(attr);
    } else {
        if (oHtml.innerText) {
            ret = oHtml.innerText;
        } else if (oHtml.firstChild && oHtml.firstChild.nodeValue) {
            ret = oHtml.firstChild.nodeValue;
        } else if (typeof oHtml.innerHTML == "string") {
            ret = oHtml.innerHTML;
        }
    }

    if (
        typeof oObj !== "undefined" &&
        typeof oObj === "object" &&
        (oObj instanceof window.UpObject || oObj instanceof upObject)
    ) {
        // we are in a new upObject Context
        switch (oObj.upType) {
            case "upFilterCtrlCalendar":
                var oControl = oObj;
                if (attr == "from") {
                    ret = oControl.getDateFrom();
                } else if (attr == "to") {
                    ret = oControl.getDateTo();
                } else if (attr == "text") {
                    var dtForm = oControl.getDateFrom();
                    var dtTo = oControl.getDateTo();
                    if (typeof dtForm == "object" && typeof dtTo == "object") {
                        // von == bis
                        if (
                            dtForm.getMonth() == dtTo.getMonth() &&
                            dtForm.getDate() === Helper.dateAdd("d", -1, dtTo).getDate()
                        ) {
                            ret = new ix.text.SimpleDateFormat(self.oUp.oFormatInfo.dateFormat).format(dtForm);
                        } else {
                            ret =
                                new ix.text.SimpleDateFormat(self.oUp.oFormatInfo.shortDateFormat).format(dtForm) +
                                " - " +
                                new ix.text.SimpleDateFormat(self.oUp.oFormatInfo.shortDateFormat).format(dtTo);
                        }
                    }
                } else {
                    ret = {
                        from: oControl.getDateFrom(),
                        to: oControl.getDateTo(),
                    };
                }
                break;
        }
    }
    return ret;
};
/**
    @public
    @function
    @memberOf Browser
    @param {Object} oControl reference to html control.
    @returns {String} Unformatted value.
    @description
    Returns unformatted value of a ViewControl or EditControl.
*/
Browser.getRawValue = function (oControl) {
    if (!oControl) {
        return false;
    }
    var oDate = null;
    var val = this.getValue(oControl);

    if (typeof val == "string" && val.length === 0) {
        return "";
    }
    if (!val) {
        return false;
    }
    var rawValue = "";

    if (oControl.oUp) {
        switch (oControl.oUp.upType) {
            case "upIntegerControl": // fall through
            case "upFloatControl": // fall through
            case "upCurrencyControl":
                rawValue = oControl.oUp.toJSNumber(val);
                break;
            case "upDateControl":
                oDate = oControl.oUp.setDateObjectFromLocal(val);
                rawValue = oControl.oUp.toISODateString(oDate);
                break;
            case "upTimeControl":
                oDate = oControl.oUp.setDateObjectFromLocal(val);
                rawValue = oControl.oUp.toISOTimeString(oDate);
                break;
            case "upDateTimeControl":
                oDate = oControl.oUp.setDateObjectFromLocal(val);
                rawValue = oControl.oUp.toISODateTimeString(oDate);
                break;
            default:
                rawValue = val;
                break;
        }
    } else {
        rawValue = val;
    }
    return rawValue;
};
