/**
 * @file upDistributionControl
 * @copyright 2004-2016 United Planet GmbH, Freiburg - All Rights Reserved.
 */
/* globals ix, upControl, Helper */
/**
    @class upDistributionControl
    @private
    @param {Object} options
    @description
    Integer Controls
*/
function upDistributionControl(options) {
    this.oHtml = null;
    this.upType = "upDistributionControl";
    this.defaults = {};
    jQuery.extend(this, {}, options);
}
upDistributionControl.prototype = new upControl();

/**
 * @typedef {Ctrl~GetValue~Settings}
 * @property {String} [type] The type to be delivered, i.e. "Date"
 * @property {Object} [ctrlSpecific] Something specific for this type of control.
 * @property {String} [ctrlSpecific2] Something specific for this type of control.
 * ...
 */
/**
 * @param {Ctrl~GetValue~Settings} [settings={}] The settings to be used.
 * @return {Object}
 * @description Get the value of the control.
 * Without settings specified, the html value will be delivered, like jQuery does.
 * That means, that for some controls, the settings parameter will be mandatory to retrieve an useful value.
 */
// eslint-disable-next-line no-unused-vars
upDistributionControl.prototype.getValue = function (settings) {
    var l_Ret;
    var txt;

    if (this.plugin) {
        txt = this.plugin.selectedHidden;
    } else {
        txt = $(this.oHtml).find("#" + this.oHtml.id + "_selected_hidden");
    }
    try {
        var obj = $.parseJSON(txt.val());

        if (obj !== null) {
            l_Ret = Helper.doCharStuffing(obj.selected);
        }
        return l_Ret;
    } catch (ex) {
        return "";
    }
};

upDistributionControl.prototype.checkValidity = function () {
    var checkRequired =
        this.required !== undefined && (this.required === true || this.required === 1 || this.required === "1");
    var selected = this.getValue();
    return {
        isValid: !checkRequired || (typeof selected === "string" && selected.length > 0),
        message: ix.text.i18n.get("CHECK_CONSTR_REQUIRED"),
    };
};

upDistributionControl.prototype.reportValidity = function () {
    var validationInfo = this.checkValidity();
    var mediator = self.oUp.mediator;
    mediator.publish("ixpage/validation/control/report/show", {
        element: this.oHtml,
        isValid: validationInfo.isValid,
        message: validationInfo.message,
    });
};

/**
 * @param {Object} val The value to be set.
 * @param {upDataControl~SetValue~Settings} [settings={}] The settings to be used.
 * @todo An neue Methodendefinition anpassen!
 * @return {Promise}
 * @description Set value of the control, can be asynchron, depending on the control type.
 */
upDistributionControl.prototype.setValue = function (value, settings) {
    var that = this; // preserve this context
    var _value = value;
    var _settings = {
        triggerOnchange: true,
    };

    _settings = $.extend({}, _settings, settings);
    return $.Deferred(function (dfd) {
        if (typeof that.oHtml !== "undefined" && typeof _value !== "undefined") {
            var _arr;
            if (value instanceof Array) {
                _arr = value;
            } else if (typeof value === "string") {
                _arr = value.split("||");
            }
            var id = that.oHtml.id;
            var txt = document.getElementById(id + "_selected_hidden");
            var _str = '{"type":"selected", "selected": [';

            for (var k = 0; k < _arr.length; k++) {
                if (k > 0) {
                    _str = _str + ",";
                }
                _str = _str + '"' + _arr[k] + '"';
            }
            _str = _str + "]}";
            txt.value = _str;
            $(txt).trigger("change");
            that.reportValidity();
            $("#" + id + "_wrapper").trigger("externalchange");
            // trigger onchange
            if (_settings.triggerOnchange) {
                $(that.oHtml).trigger("change");
            }
            dfd.resolve();
        } else {
            dfd.reject();
        }
    }).promise();
};
